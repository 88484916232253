@import '../../../styles/colors.scss';

$previewBorderRadius: 10px;
$previewBorderSize: 1px;

.width.width {
  width: 75%;
}

.preview {
  border-radius: $previewBorderRadius;
  padding: 10px;
  border: #{$previewBorderSize} solid #{map-get($colors, 'grey-hover')};
  .title {
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    height: 38px;
    text-align: left;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
}
