@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';
.pickerWrapper {
  display: inline-flex;
  flex-flow: row nowrap;
}

.picker,
.invalidPicker,
.disabledPicker {
  min-height: 39px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
}

.disabledPicker {
  background: map-get($colors, 'grey-bkgd');
  border: none;
}

.invalidPicker {
  border: 1px solid map-get($colors, 'red-hover');
  background: map-get($colors, 'red-light');
}
.calendarIcon {
  top: 7px;
  position: relative;
}

.time,
.invalidTime {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  &.onlyTime {
    border-left: 1px solid;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .textbox {
    border-radius: 5px;
    width: 61px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    text-align: center;
    font-size: 16px;
    border: 1px solid map-get($colors, 'grey-stroke');
    border-right: 0;
  }

  :disabled {
    background: map-get($colors, 'grey-bkgd');
    border: 0;
  }

  ::placeholder {
    font-style: oblique;
  }

  .dropdown {
    padding: 0;
    > div {
      min-width: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.invalidTime {
  background: map-get($colors, 'red-light');
  border: 1px solid map-get($colors, 'red-hover');

  &.textbox {
    border: 1px solid map-get($colors, 'red-hover');
  }
}

.selected {
  background-color: map-get($colors, 'blue');
}
