@import '../../../styles/colors.scss';
.instructions {
  text-align: left;
}
.signatures {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.signatureBox {
  padding: 10px;
  margin: 10px;
  border: 2px solid map-get($colors, 'grey-stroke');
  border-radius: 10px;
}
.button {
  width: fit-content;
}

.image {
  width: 80px;
  height: 80px;
  cursor: pointer;
  object-fit: contain;
  padding: 12px;
}
