@import '../../styles/colors.scss';

.dashboard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid map-get($colors, 'grey-stroke');
  border-radius: 5px;
  padding: 20px;
  background: map-get($colors, 'white');
  box-shadow: 0px 0px 2px 1px map-get($colors, 'white-box-shadow');

  .title {
    font-weight: bold;
    margin-top: 10px;
  }
}

.sharedInfo {
  margin: auto;
  align-self: center;
}

.options {
  display: flex;
  justify-content: center;
  padding: 30px 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > p {
      background-color: map-get($colors, 'blue-light');
      font-size: 12px;
      line-height: 14px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      position: absolute;
      padding: 2.5px;
      top: -15px;
      right: 5px;
    }

    > div {
      width: 50px;
      height: 50px;
      background: map-get($colors, 'blue');
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;

      &:hover {
        background: radial-gradient(
          map-get($colors, 'blue-hover') 20%,
          map-get($colors, 'blue') 50%,
          map-get($colors, 'white') 75%
        );
        cursor: pointer;
      }

      svg {
        fill: map-get($colors, 'white');
        stroke: map-get($colors, 'white');
        width: 20px;
        height: 20px;
      }
    }
  }
}

.bottom {
  display: flex;

  .favorite {
    width: 40px;
    height: 40px;
    stroke: map-get($colors, 'yellow');
    &.isFavorite {
      fill: map-get($colors, 'yellow');
    }
    &:hover {
      fill: map-get($colors, 'yellow-light');
    }
  }

  .trash {
    stroke: map-get($colors, 'grey-stroke');
    fill: map-get($colors, 'grey-stroke');
    width: 40px;
    height: 40px;
    &:hover {
      stroke: map-get($colors, 'grey');
      fill: map-get($colors, 'grey');
    }
  }
}
