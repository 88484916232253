.input {
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;
  margin-bottom: 20px;
}

.label {
  min-height: 21px;
  max-height: fit-content;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 10px;
  font-style: normal;
  text-align: left;
  display: block;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.input:focus {
  outline: none;
}

.input:disabled {
  filter: opacity(50%);
}

.isRequired {
  color: #ff0000;
}
