@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.badEntry {
  input {
    background-color: map-get($colors, 'red-light');
    border-color: map-get($colors, 'red');
  }
}

.microsoftLoginButton {
  button {
    color: map-get($colors, 'blue') !important;
    border: 2px solid map-get($colors, 'blue') !important;
  }
  img {
    margin-right: 10px;
  }
}
