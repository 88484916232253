@import '../../../styles/colors.scss';

.stages {
  width: inherit;
  height: 63px;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 10px 20px;

  .stage {
    width: 30%;
    height: 46px;

    &:first-child > div {
      border-radius: 100px 0 0 100px;
    }
    &:last-child > div {
      border-radius: 0px 100px 100px 0px;
    }

    > div {
      @include colorHover('grey');
      height: 34px;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    > img {
      width: 24px;
      position: relative;
      top: -8px;
    }

    &.stageStarted > div {
      @include colorHover('blue');
    }

    &.stagePastDue > div {
      @include colorHover('red');
    }

    &.stageCompleted > div {
      @include colorHover('green');
    }

    &.stageDisabled > div {
      background-color: map-get($colors, 'grey');
      cursor: not-allowed;
      &:hover {
        background-color: map-get($colors, 'grey');
      }
    }

    &.stageStarted:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'blue');
    }
    &.stageStarted:not(:last-child) > div:hover::after {
      border-left-color: map-get($colors, 'blue-hover');
    }

    &.stagePastDue:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'red');
    }
    &.stagePastDue:not(:last-child) > div:hover::after {
      border-left-color: map-get($colors, 'red-hover');
    }

    &.stageCompleted:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'green');
    }
    &.stageCompleted:not(:last-child) > div:hover::after {
      border-left-color: map-get($colors, 'green-hover');
    }

    &.stageDisabled:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'grey');
    }
    &.stageDisabled:not(:last-child) > div:hover::after {
      border-left-color: map-get($colors, 'grey');
    }

    &:not(:last-child) > div::after {
      position: relative;
      top: -28px;
      left: 100%;
      display: block;
      border-left: 16px solid map-get($colors, 'grey'); /* width: arrow width */
      border-top: 17px solid transparent; /* width: half height */
      border-bottom: 17px solid transparent; /* width: half height */
      width: 0;
      height: 0;
      content: ' ';
    }
    &:not(:last-child) > div:hover::after {
      border-left-color: map-get($colors, 'grey-hover');
    }

    &:not(:first-child):not(:last-child) > div::after {
      top: -62px;
    }

    &:not(:first-child) > div::before {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      border-left: 16px solid #fff; /* width: arrow width */
      border-top: 17px solid transparent; /* width: half height */
      border-bottom: 17px solid transparent; /* width: half height */
      width: 0;
      height: 0;
      content: ' ';
    }

    &.stageSelected {
      padding-bottom: 10px;
      border-bottom: 2px map-get($colors, 'grey') solid;
    }
  }
}
