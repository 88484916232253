@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.requiredText {
  color: map-get($colors, 'red');
}
.buttons {
  margin-top: 0 !important;
  padding-bottom: 10px;
  width: 40.5%;
  max-height: 53px;

  &.missingRequired {
    background-color: map-get($colors, 'red-light');
    border: 1px solid map-get($colors, 'red-hover');
    border-radius: 5px;
  }
}
.timeTextbox {
  width: 10% !important;
}
.fileUpload {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: -100;
  opacity: 0;
  width: 155px;
  height: 36px;
}
.materialRow {
  text-align: start;
}

.materials {
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  :hover {
    background-color: map-get($colors, 'grey-bkgd');
  }
  color: black;
  text-decoration-line: none;
  cursor: pointer;
  margin: 5px;
}

.training {
  width: 295px;
  height: 90px;
  background: #ffffff;
  border: 1px solid map-get($colors, 'grey-stroke');
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0;
  display: inline-flex;
  align-items: center;

  .deleteButton {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .trainingFileName {
    font-weight: bold;
    max-height: 60px;
    max-width: 12em;
    font-size: 14px;
    max-block-size: 35px;
    overflow-y: hidden;
    text-overflow: hidden;
    text-wrap: wrap;
    padding: 0;
    margin: 0;
    margin-bottom: 2px;
    line-break: anywhere;
  }

  .trainingAttachment {
    width: 80px;
    height: 80px;
    cursor: pointer;
    object-fit: contain;
    padding: 12px;
  }
  .timeViewed {
    justify-self: flex-start;
  }
}
