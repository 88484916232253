@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  background: map-get($colors, 'white');
  border-radius: 10px;
  padding-bottom: 18px;
  width: 631px;

  .titleRow,
  .locationRow {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }

  .titleRow {
    align-items: center;
    justify-content: space-between;
  }

  .title {
    height: 28px;
    font-weight: 700;
    display: flex;
    font-size: 24px;
    line-height: 28px;
    color: map-get($colors, 'blue');
    text-align: left;
  }

  .locationName {
    background-color: map-get($colors, 'blue-light');
    width: fit-content;
    border-radius: 100px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
  }
}

.table {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 65px 368px 158px;
  grid-auto-rows: 41px;
  row-gap: 5px;
  column-gap: 10px;
  margin-left: 20px;
  margin-right: 20px;

  &.even {
    @include color('row-grey', $background: true);
    border-radius: 10px;
  }
}

.header,
.columnType,
.data,
.score,
.rank {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
}

.data,
.score {
  font-weight: 400;
}

.rank,
.score {
  text-align: center;
}

.noDataMessage {
  font-weight: 700;
  padding: 5px;
  text-align: center;
  font-size: 22px;
  line-height: 24px;
}

.columnType {
  display: inline-flex;
  text-align: center;
}

.divider {
  position: static;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  margin-left: 20px;
  margin-right: 20px;
}

.buttonImage {
  margin-left: 4%;
  justify-self: center;
  align-self: center;
}

.tooltip {
  background-color: #{map-get($colors, 'white')} !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2)) !important;
  font-size: 18px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: auto;
  white-space: pre-wrap;
}

.extraGroupsBubble {
  color: #{map-get($colors, 'black')};
  background: map-get($colors, 'blue-light');
  border-radius: 20px;
  width: fit-content;
  padding: 3px 10px;
  margin: 2.5px 0px;
  flex-direction: row;
  display: flex;
}
