@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

@include media('<=tablet') {
  .sidebar {
    display: none;
  }

  .hideSidebar {
    display: none;
  }
}

@include media('>tablet') {
  .sidebar {
    order: 1;
  }
}
aside {
  margin: 0;
}

.sidebar {
  background-color: map-get($colors, 'nav-grey');
  padding: 10px;
  padding-top: 20px;
  height: 100%;
  min-width: 15%;
  max-width: 20%;
  position: relative;
  overflow: auto;

  p {
    text-align: left;
  }
}

.hideSidebar {
  background-color: map-get($colors, 'nav-grey');
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 20px;
  z-index: 0;
}

.hideSections {
  display: none;
}

.bottomNav {
  color: #fff;
  margin: 20px;
  display: flex;
  flex-flow: column wrap;

  button {
    border-radius: 18px;
  }
}
