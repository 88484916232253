@import '../../containers/MobileHeader/mobileHeader.module.scss';

.active {
  &:hover {
    background-color: map-get($colors, 'green') !important;
  }
  @include color('green', $background: true);
  border-radius: 5px;
}

.item {
  padding: 5px 5px 5px 50px !important;

  &.hasFolders {
    padding-left: 30px !important;
    &::before {
      content: url('../../assets/images/whiteChevron.png');
      float: left;
      margin-top: 3px;
      margin-right: 5px;
      margin-left: 8px;
    }
    &.active {
      &::before {
        content: url('../../assets/images/whiteChevron.png');
        rotate: 90deg;
        float: left;
        justify-items: center;
        margin-right: 9px;
        margin-left: 4px;
      }
    }
  }
}
.addFolderButton {
  margin-left: 56px;
  border-color: 'white';
}

.folderList {
  list-style: none;
  color: map-get($colors, 'white');
  padding-left: 0;
  width: auto;
  > li {
    @include color('black', $shadow: true);
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    &.addFolderButton {
      padding-left: 70px;
    }
    &.folderItem {
      .addButton {
        width: 16px;
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
      padding: 6px 0 6px 80px;
      border-radius: 5px;
      &:hover {
        background-color: map-get($colors, 'nav-grey-hover');
      }
      &.selected {
        @include color('green', $background: true, $text: true);
        margin: 0;
        height: fit-content;
      }
    }
  }
}
