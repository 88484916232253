@import '../../../styles/colors.scss';

.trainingTemplate {
  display: flex;
  margin-bottom: 0;

  .fileUpload {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: -100;
    opacity: 0;
    width: 155px;
    height: 36px;
  }

  .attachmentContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    box-shadow: inset map-get($colors, 'black-shadow');
    height: fit-content;
    margin-top: 20px;
  }
}
.overallFooter {
  width: 960px;
  margin: 20px auto;
}
.trainingCards {
  width: 100%;
  line-height: 5px;
  text-align: left;
}
.trainingCard {
  margin-bottom: 10px;
  background-color: white;
}
.trainingText {
  margin-left: 35px;
}
.errorText {
  font-weight: 'bold';
  color: map-get($colors, 'red-hover');
}
.linkText {
  cursor: pointer;
  text-decoration: underline;
  color: map-get($colors, 'blue');
}
.trainingRequiredButton {
  background-color: map-get($colors, 'red-light') !important;
  border: 1px solid map-get($colors, 'red-hover') !important;
  color: map-get($colors, 'red-hover') !important;
  box-shadow: 0px 0px 1px map-get($colors, 'red-hover');
  font-weight: bold;
}
