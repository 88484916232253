.reportPrivateLog {
  min-height: 76px;
  max-height: 198px;
  width: 180px;
  margin: 0 auto 20px;
  position: relative;
}

.reportPrivateLogCard {
  min-height: inherit;
  max-height: inherit;
  width: inherit;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 20px;
}

.reportPrivateLog:hover .reportPrivateLogCard {
  background: rgba(0, 0, 0, 0.5);
  filter: blur(2px);
  border-radius: 10px;
}

.reportPrivateLogCard-createdDate {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #808080;
  padding: 0 7.5px;
}

.reportPrivateLogCard-comment {
  width: 178px;
  min-height: 34px;
  max-height: 156px;
  background: #f1f2f2;
  border: 1px solid #c4c4c4;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
}

.reportPrivateLogCard-commentBox {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: inherit;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  padding: 5px;
}

.reportPrivateLogCard-badge {
  height: 42px;
  display: flex;
  border: 1px solid #c4c4c4;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
}

.reportPrivateLogCard-badge div.name.name span {
  font-style: italic;
  font-size: 14px;
}

.reportPrivateLogCard-buttons {
  display: none;
}

.reportPrivateLog:hover .reportPrivateLogCard-buttons {
  display: flex;
  justify-content: space-around;
  height: 36px;
  transform: translateY(-240%);
}

.reportPrivateLog:hover .reportPrivateLogCard-buttons * {
  min-width: 45px;
  margin: auto;
}
