@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.questions {
  height: fit-content;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.sectionTitle {
  flex-grow: 10;
}
.sectionRow {
  display: flex;
  align-items: flex-end;
}

.item {
  display: flex;
  align-items: center;

  .question {
    flex-grow: 1;
  }

  &.target {
    background-color: map-get($colors, 'grey-bkgd');
  }
}
.options {
  display: flex;
  align-items: center;

  .option {
    flex-grow: 1;
  }
  .removeImage img {
    max-width: 38px;
  }
}

.overallFooter {
  margin: auto;
}
.error {
  color: map-get($colors, 'red');
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  margin: 5px;
}
.buttonBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}
.dupAndRemoveImages {
  display: flex;
  align-self: center;
}
.arrangeButtons {
  border: 1px solid map-get($colors, 'grey-stroke');
  border-radius: 8px;
  width: 45px;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: map-get($colors, 'white-hover');
  }
}
.disabled {
  background-color: map-get($colors, 'white-hover');
  cursor: not-allowed;
}
.downButton {
  margin-left: 1em;
  rotate: 180deg;
}
