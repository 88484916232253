@import '../../styles/colors.scss';

.share {
  > div {
    border: 1px solid map-get($colors, 'blue');
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      margin-right: 25px;
      cursor: pointer;
      color: map-get($colors, 'red');
    }

    .shareOptions {
      margin-left: auto;
    }

    &.expired {
      border-color: map-get($colors, 'red');

      .expireMessage {
        margin-left: 10px;
        margin-top: 5px;
        color: map-get($colors, 'red');
      }
    }
  }
}
