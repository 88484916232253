.oshaLog {
  padding-left: 40px;
  padding-top: 20px;
  width: 100%;
  height: 771px;
  overflow-x: scroll;
}

.oshaLog-header {
  display: flex;
}

.oshaLog-tabs {
  display: flex;
}

.oshaLog-tab {
  border: 1px solid #d0d1d1;
  width: 282px;
}

.oshaLog-tabs--headerTopLine {
  font-size: 18px;
  font-weight: 600;
}

.oshaLog-tabs--headerBottomLine {
  font-size: 24px;
  font-weight: 600;
}

.oshaLog-tab1 {
  border-radius: 6px 0px 0px 0px;
  padding: 10px 24px 16px;
  text-align: left;
}

.selected {
  background: #ffffff;
  z-index: 1;
  border-bottom: none;
}

.oshaLog-tab2 {
  padding: 10px 85px;
  text-align: left;
  border-radius: 0px;
}

.oshaLog-tab3 {
  padding: 10px 45px 10px;
  text-align: left;
  border-radius: 0px 6px 0px 0px;
}

.oshaLog-tab--selected {
  padding: 0px;
  margin-top: 11px;
  background: #58a15b;
  height: 2px;
  width: 222px;
}

.oshaLog-tab2 .oshaLog-tab--selected {
  margin: 10px -50px;
}

.oshaLog-tab3 .oshaLog-tab--selected {
  margin: 10px -20px;
}

.oshaLog-tab--notSelected {
  background: #e4e5e5;
}

.oshaLog-tab--notSelected:hover {
  background: #d0d1d1;
}

.oshaLog-tabContent {
  position: relative;
  top: -1px;
  background: #ffffff;
  border: 1px solid #d0d1d1;
  border-radius: 0px 6px 6px 6px;
  width: 100%;
  height: 707px;
  padding: 10px 20px 80px 20px;
  overflow: scroll;
}

.oshaLogTab1-noContent {
  position: relative;
  width: 438px;
  height: 48px;
  left: calc(50% - 438px / 2 + 0.5px);
  top: calc(50% - 48px / 2 + 0.5px);
  line-height: normal;
  font-size: 24px;
}

.oshaLog-selection {
  align-items: center;
}

.oshaLog-selection--dropdown {
  margin-right: 30px;
  padding: 0;
  margin-bottom: 0;
}

.oshaLog-selection--button {
  width: 140px;
}

.hierarchyTabs {
  margin-top: 0px;
  margin-bottom: 1em;
  margin-left: 5px;
}
