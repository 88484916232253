@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

.modalButtons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  @include media('<=desktop') {
    grid-template-columns: repeat(2, minmax(20%, 1fr));
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0;
    padding: 0;
  }
}
.button {
  min-height: 80px;
  min-width: 30px;
  flex: 1 1 20%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0px !important;
  @media (max-width: 500px) {
    justify-content: center;
  }
}

.buttonText {
  font-size: 18px;
  font-weight: 700;
  margin: 5px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  @media (max-width: 500px) {
    display: none;
  }
}
.imageStyles {
  justify-self: flex-start;
  border-radius: 50px;
  padding: 8px;
  margin: 15px 10px 15px;
  background-color: map-get($colors, 'blue');
}

.tooltip {
  @media (min-width: 500px) {
    display: none;
  }
}
