@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';
.textbox {
  background: #ffffff;
  border: 1px solid #949494;
  box-shadow: inset 0px 0px 3px rgba(191, 191, 191, 1);
  min-height: 38px;
  width: 50px;
  text-align: center;
  font-size: 16px;
  padding: 7.5px;
  display: block;
  border-right: none;
  border-left: none;
  &.error {
    background: map-get($colors, 'red-light');
  }
}

.textbox:disabled {
  background: #f1f2f2;
  cursor: not-allowed;
  box-shadow: none;
  border: 1px solid #f1f2f2;
}

.textbox::placeholder {
  color: #5f5f5f;
  font-style: oblique;
}
.stepperContainer {
  display: inline-flex;
}

.minus {
  color: white;
  padding: 15px 10px;
  margin: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: map-get($colors, 'blue');
  user-select: none;
  cursor: pointer;
}
.plus {
  color: white;
  padding: 15px 10px;
  margin: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: map-get($colors, 'blue');
  user-select: none;
  cursor: pointer;
}
.disabled {
  background-color: map-get($colors, 'blue-light');
  cursor: not-allowed;
}
