.photoEvidenceCard {
  min-height: 405px;
  max-height: fit-content;
  width: 960px;
  margin: auto;
}

.photoEvidenceCard-left {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 35%;
  justify-content: space-between;
}

.photoEvidenceCard-right {
  padding: 10px;
  width: 35%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column-reverse;
}
.photoEvidenceCard-time {
  flex-direction: column;
}

.photoEvidenceCard-photoBig {
  width: 100%;
  height: 600px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  background: #5f5f5f;
  display: flex;
  justify-content: space-between;
}

.photoEvidenceCard-photoSmall {
  width: 100%;
  height: 272px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  background: #5f5f5f;
  display: flex;
  justify-content: space-between;
}

.photoEvidenceCard-photoSmall .photoImage,
.photoEvidenceCard-photoBig .photoImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.photoEvidenceCard-photoSmall:hover .photoImage,
.photoEvidenceCard-photoBig:hover .photoImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background: #2b2b2b;
  filter: opacity(0.5);
}

.photoEvidenceCard-photoSmall .photoImageCensored,
.photoEvidenceCard-photoBig .photoImageCensored {
  position: relative;
  top: 40px;
  height: calc(100% - 80px);
  width: calc(100% - 80px);
  object-fit: contain;
  filter: blur(40px);
}

.allButtons,
.allButtonsCensored {
  display: none;
}

.photoEvidenceCard-photoSmall:hover .allButtonsCensored {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  top: -150px;
  height: 100%;
}

.photoEvidenceCard-photoBig:hover .allButtonsCensored {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  top: -450px;
  height: 100%;
}

.photoEvidenceCard-photoSmall:hover .allButtons {
  display: flex;
  justify-content: censored;
  flex-direction: column;
  position: relative;
  top: -165px;
  height: 100%;
}

.photoEvidenceCard-photoBig:hover .allButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: -600px;
  height: 100%;
}

.allButtons .button,
.allButtonsCensored .button {
  min-width: 92px;
  margin: 0px 25px;
  width: 125px;
}

.photoEvidenceCard-photoInfo {
  background: #fff;
  height: fit-content;
  border: 1px solid #008284;
  border-radius: 0px 0px 5px 5px;
  padding: 20px;
}

.photoEvidenceCard-expandDespanButton {
  display: none;
}

.photoEvidenceCard-photoSmall:hover .photoEvidenceCard-expandDespanButton,
.photoEvidenceCard-photoBig:hover .photoEvidenceCard-expandDespanButton {
  display: initial;
}

.photoEvidenceCard-expandDespanButton img {
  width: 50px;
  height: 50px;
}

.photoEvidenceCard-uploadedAt {
  display: none;
}

.photoEvidenceCard-photoSmall:hover .photoEvidenceCard-uploadedAt,
.photoEvidenceCard-photoBig:hover .photoEvidenceCard-uploadedAt {
  display: flex;
  color: #ffffff;
}

.photoEvidenceCard-censored {
  display: none;
}

.photoEvidenceCard-photoSmall:hover .photoEvidenceCard-censored,
.photoEvidenceCard-photoBig:hover .photoEvidenceCard-censored {
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 0;
  left: 0;
  display: block;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.uploadedAt-date,
.uploadedAt-name {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
}

.uploadedAt-by,
.uploadedAt-name {
  text-align: left;
}

.photoEvidenceCard-tagsCollapsed {
  display: flex;
  margin-bottom: 10px;
}

.photoEvidenceCard-tagsCollapsed .gear {
  border-right: 2px solid #008284;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.photoEvidenceCard-tagsCollapsed .gear img {
  width: 24px;
  height: 24px;
}

.photoEvidence-tagsCollapsed--noTags {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
}

.photoEvidence-tagsCollapsed--noTagsWord {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.photoEvidenceCard-tagsExpanded {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.photoEvidenceCard-tagsExpanded .closeButton {
  display: flex;
  border-right: 2px solid #008284;
  margin-right: 20px;
  padding-right: 10px;
  align-items: center;
  cursor: pointer;
}

.photoEvidenceCard-tagsExpanded .closeButton img {
  width: 24px;
  height: 24px;
  background: #fff;
}

.photoEvidence-tagsExpanded--tags {
  height: fit-content;
  width: inherit;
  border: 2px solid #008284;
  border-radius: 0px 12px 12px 0px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.photoEvidence-tagsExpanded--censoredTags {
  display: flex;
  color: #c74846;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  border-bottom: 2px solid #c4c4c4;
}

.photoEvidence-tagsExpanded--defaultTags {
  display: flex;
  flex-flow: row wrap;
  color: #008284;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  /* border-bottom: 2px solid #c4c4c4; */
}

.photoEvidence-tagsExpanded--censoredTags .photoEvidence-tags,
.photoEvidence-tagsExpanded--defaultTags .photoEvidence-tags {
  margin: 10px;
}

.photoEvidence-tagsExpanded--customTags {
  display: flex;
  flex-flow: row wrap;
  color: #5f5f5f;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.photoEvidence-tags {
  width: fit-content;
  padding: 1px 10px;
  border-radius: 100px;
  margin: 0 10px;
  cursor: pointer;
}

.photoEvidence-tags:hover {
  background: #d9d9d9;
  border-radius: 100px;
  height: 24px;
}

.photoEvidence-tags div {
  margin-top: 4px;
}

.censoredSelected {
  background: #c74846;
  color: #fff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  word-wrap: nowrap;
}

.censoredSelected:hover {
  background: #a13a38;
}

.defaultSelected {
  background: #008284;
  color: #fff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  word-wrap: nowrap;
}

.defaultSelected:hover {
  background: #005d5e;
}

.customTags-header {
  width: fit-content;
  height: 24px;
  padding: 3px 10px;
  margin: 10px 0px 10px 10px;
}

.customTag-addTag {
  border: 1px solid #949494;
}

.customTag-addTag img {
  height: 14px;
  width: 14px;
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.photoEvidence-customTags--disclaimer {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #5f5f5f;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin-left: 10px;
}

.photoEvidence-peopleTags.photoEvidence-peopleTags {
  margin-top: 0;
}

.wide {
  width: calc(100% - 40px);
  margin: 20px;
}
