@import '../../../styles/colors.scss';

.userAccessLevel {
  font-size: 24px;
  font-weight: 700;
  @include color('white-shadow', $shadow: true);
  line-height: 28px;
}

.twoColumn {
  grid-template-columns: 0.5fr 1fr;
}

.inputRow {
  margin-bottom: 20px;
}

.nameBox {
  flex-grow: 1;
}

.right {
  justify-content: flex-end;
}

.fileInput {
  font-size: 100px;
  position: absolute;
  left: -305px;
  top: 0;
  opacity: 0;
  width: 206px;
  height: 206px;
  cursor: pointer;
}
