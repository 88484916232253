@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.container {
  height: fit-content;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  $hoverHere: &;
  display: flex;
  align-items: center;

  .removeImage,
  .duplicateImage,
  .editImage {
    cursor: pointer;
    opacity: 0;
    margin-left: 10px;
    img {
      width: 38px;
      height: 38px;
    }

    @include atContext($hoverHere, ':hover') {
      opacity: 100;
    }
  }
  .field {
    flex-grow: 1;
  }

  &.disabled {
    .dragImage,
    .removeImage,
    .duplicateImage,
    .editImage {
      display: none;
    }
  }

  &.target {
    border-style: dashed;
    border-width: 2px;
  }
}
.disabled {
  display: none;
}
.footer {
  border-radius: 0;
  border-top: 1px solid #c4c4c4;
  padding-top: 15px;
  margin-top: 10px;
}

.buttonBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dupAndRemoveImages {
  display: flex;
  align-self: center;
}

.listItem {
  border: 1px solid #008284;
  border-radius: 6px;
  margin: 2px;
  padding: 1em;
  &.target {
    border-style: outset dotted;
    border-width: 3px;
    background-color: map-get($colors, 'grey-bkgd');
  }
}

.modal {
  max-height: 4000px;
  overflow-y: auto;
  .options {
    display: flex;
    align-items: center;
    .removeImage {
      height: 25px;
      img {
        height: 25px;
        width: 25px;
      }
    }

    .option {
      flex-grow: 1;
    }
  }
}
.errorBox {
  border-color: map-get($colors, 'red');
  background-color: map-get($colors, 'red-light') !important;
  cursor: pointer;
}
