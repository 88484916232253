@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

div.multiSelect {
  $multiSelect: &;
  min-height: 39px;
  max-height: fit-content;
  flex-wrap: wrap;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  border-radius: 5px;
  margin: 0;
  min-width: 155px;
  @include colorHover('white');
  @include color('white', $text: true);
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7.5px;

  &.permDropdown {
    min-width: 0;
  }

  &.isInvalid {
    background-color: map-get($colors, 'red-light');
    border: 1px solid map-get($colors, 'red-hover');
  }

  div.right {
    position: absolute;
    right: 5px;
    > img {
      width: 10px;
      height: 5px;
      margin: 0;
      padding: 0;
      position: relative;
      top: -2.5px;

      @include atContext($multiSelect, '.disabled') {
        visibility: hidden;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: map-get($colors, 'grey-bkgd');
    border: none;
  }

  &.showOptions {
    z-index: 10;
    &:not(.openUp) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &.openUp {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  &.permDropdown {
    justify-content: center;
  }

  > span {
    padding-left: 10px;
    line-height: 22px;
    min-height: 22px;
    max-width: 100%;
    padding-right: 20px;
    font-size: 16px;
    font-weight: normal;

    @include atContext($multiSelect, '.permDropdown') {
      padding: 0;
    }

    @include atContext($multiSelect, '.clipText') {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.placeHolder {
      color: map-get($colors, 'grey');
      font-style: oblique;
    }

    &.invalidPlaceHolder {
      font-weight: 400;
      color: map-get($colors, 'red-hover');
      font-style: oblique;
    }

    &.multiText {
      border-radius: 20px;
      background-color: map-get($colors, 'blue');
      color: map-get($colors, 'white');
      margin: 3px;
      display: flex;
      padding-right: 10px;

      div {
        margin-left: 10px;
      }
    }

    &.defaultAnswers {
      background-color: map-get($colors, 'blue-light');
      color: map-get($colors, 'black');
    }
  }
}

.picture {
  width: 22px;
  height: 22px;
  display: flex;
  position: relative;
}

ul.dropdown {
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: scroll;
  background-color: map-get($colors, 'white');
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  box-shadow: inset 0px 0px 3px rgba(191, 191, 191, 1);

  &.showOptions {
    display: initial;
  }

  &:not(.openUp) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;
  }
  &.openUp {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
  }
  &.isInvalid {
    background-color: map-get($colors, 'red-light');
  }

  > li.option,
  > li.optionSelected {
    min-height: 38px;
    max-height: fit-content;
    text-align: left;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    padding: 10px 5px;
    align-items: center;
    @include colorHover('white');

    img {
      max-width: 47px;
      max-height: 20px;

      .green {
        filter: invert(42%) sepia(26%) saturate(1056%) hue-rotate(75deg)
          brightness(97%) contrast(93%);
      }
    }
  }

  > li.optionSelected {
    @include color('blue', $hover: true, $text: true, $background: true);
  }

  .row {
    width: 100%;

    .grow {
      flex-grow: 1;
    }

    .button button {
      min-width: 0;
    }
  }
}

.calendar {
  @include color('white', $background: true);
}

.border {
  background-color: map-get($colors, 'blue-light');
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  margin: 7.5px;

  .filterText {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: map-get($colors, 'grey');
    display: flex;
    align-items: center;
    padding: 7px;
  }
}

.filterButton {
  border-radius: 40px;
  min-width: 40px;
}

.filterOptionText,
.filterCheckedText {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 7;
}

.filterCheckedText {
  color: map-get($colors, 'blue');
}

.filterCheckbox {
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  padding: 0;
  border: 2px solid map-get($colors, 'grey-stroke');
  border-radius: 5px;
  background-color: map-get($colors, 'white');
  display: grid;
  align-items: center;

  .input.disabled & {
    border: none;
    background-color: map-get($colors, 'grey-bkgd');
    cursor: not-allowed;
  }

  img {
    width: 15px;
    height: 15px;
  }
}
