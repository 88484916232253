@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.picker {
  height: auto;
  &.listPicker {
    margin: 20px 20px 0 20px;
  }
  .input {
    margin: 0;
    padding: 0;
    flex-direction: row;
    align-items: center;

    > input {
      padding: 7.5px;
      width: 100%;
    }

    > label {
      height: 100%;
      display: flex;
      padding-right: 0.4em;
    }

    > span {
      white-space: nowrap;
      margin-bottom: 0;
      height: $inputHeight;
      line-height: $inputHeight;
      margin-right: 5px;
    }
  }

  .period {
    & + .input,
    & + .period,
    & + .absolute {
      margin-left: 1em;
    }

    display: flex;
    align-items: center;
    > .time {
      width: 4em;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.twoColumn {
  grid-template-columns: 3fr 3fr 3fr 3fr 3fr 3fr;
}

.datePicker {
  :global(.SingleDatePicker .SingleDatePickerInput) {
    border-radius: 5px;
    // padding: 1px;
    /* Sorry.  It's !important somewhere else. */
    width: auto !important;

    :global(.DateInput) {
      width: min-content !important;
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        width: 110px !important;
      }
    }
  }

  :global(.react-datepicker-popper) {
    z-index: 9999 !important;
  }
}

.datePickerInvalid {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;

  :global(.SingleDatePicker .SingleDatePickerInput) {
    border-radius: 5px;
    padding: 1px;
    /* Sorry.  It's !important somewhere else. */
    width: auto !important;
    background-color: map-get($colors, 'red-light');
    border: 1px solid map-get($colors, 'red-hover');

    :global(.DateInput) {
      width: min-content !important;
      background-color: map-get($colors, 'red-light');
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        width: 110px !important;
        background-color: map-get($colors, 'red-light');
      }
    }
  }
}

.save.save.save {
  margin-left: 0;
  margin-top: 0;
  padding: 0;
}
.warning {
  color: map-get($colors, 'red');
  flex-direction: row-reverse;
  font-family: Helvetica;
  font-size: 14px;
  padding: 0px 100px 0px 10px;
}

.popperPlacement {
  z-index: 9999 !important;
  padding-bottom: 10px;
}
