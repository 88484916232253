@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';
.radioButtoninput {
  display: flex;
  flex-flow: row nowrap;
  padding: 5px;
  font-size: 1em;
  margin-left: 35px;
  margin-top: 0px;
}
.radioButtonDiv {
  cursor: pointer;
  $radioButtonDiv: &;
  .radioButton {
    @include atContext($radioButtonDiv, ':hover') {
      background-color: map-get($colors, 'white-hover');
    }
  }
  .radioButtonErr {
    @include atContext($radioButtonDiv, ':hover') {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.radioButtonColInput {
  display: flex;
  flex-flow: row nowrap;
  padding: 5px;
  font-size: 1em;
  margin-left: 0px;
  margin-bottom: 15px;
}

.radioButtonsRow {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.radioButtonsCol {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.radioImage {
  max-width: 300px;
  max-height: 300px;
  align-self: center;
}

.radioButtonLabel {
  display: block;
  position: relative;
  padding-left: 50px;
  padding-top: 12px;
  cursor: pointer;
  line-height: 18px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.radioButtonLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radioButton {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 0px;
  top: calc(50% - 31px / 2);
  background: map-get($colors, 'white');
  border: 2px solid map-get($colors, 'grey-stroke');
  border-radius: 19px;
}
.radioButtonLabel input:checked ~ .radioButton {
  background-color: map-get($colors, 'white');
}

.radioButtonErr {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 0px;
  top: calc(50% - 31px / 2);
  background: map-get($colors, 'red-light');
  border: 2px solid map-get($colors, 'red-hover');
  border-radius: 19px;
}

.radioButton:after {
  content: '';
  position: absolute;
  display: none;
}

.radioButtonLabel input:checked ~ .radioButton:after {
  display: block;
}

.radioButtonLabel .radioButton:after {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 19px;
  top: calc(50% - 20px / 2);
  left: calc(50% - 20px / 2);
  background: map-get($colors, 'blue');
}

.radioButtonDisabled {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 0px;
  top: calc(50% - 31px / 2);
  background: map-get($colors, 'grey-bkgd');
  border: none;
  border-radius: 19px;
  cursor: not-allowed;
}

.radioButtonLabel input:checked ~ .radioButtonDisabled {
  background-color: map-get($colors, 'grey-bkgd');
}

.radioButtonDisabled:after {
  content: '';
  position: absolute;
  display: none;
}

.radioButtonLabel input:checked ~ .radioButtonDisabled:after {
  display: block;
}

.radioButtonLabel .radioButtonDisabled:after {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 19px;
  top: calc(50% - 20px / 2);
  left: calc(50% - 20px / 2);

  background: map-get($colors, 'blue');
}
.containerBox {
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  border-radius: 5px;
  margin: 3px;
  flex-grow: 1;
  min-width: 30%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 16px;
}
.correct {
  background-color: map-get($colors, 'green-light');
}
.incorrect {
  background-color: map-get($colors, 'red-light');
}
.error {
  color: map-get($colors, 'red-hover');
}
