@import '../../../styles/colors.scss';

.sectionTitle {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  text-align: left;
}

.progressBar {
  justify-content: left;
  width: inherit;
}

.overallFooter {
  margin: auto;
}

.tooltip {
  display: flex;
  flex-direction: column;
  > div {
    margin: 7px;
    > span {
      @include colorStyling($text: true);
    }
    @include colorStyling($text: true);
  }
}

.flipChevron {
  transform: rotate(180deg);
}
