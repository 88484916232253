@import '../../../styles/colors.scss';

.instructions {
  text-align: left;
}

.requiredText {
  color: map-get($colors, 'red');
}

.missingRequiredText {
  color: map-get($colors, 'red-hover');
  background: map-get($colors, 'red-light');
  padding: 8px;
  border-radius: 5px;
  margin-top: -8px;
}
