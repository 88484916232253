@import '../../../styles/colors.scss';

.overallFooter {
  width: 960px;
  margin: 20px auto;
}
.trainingCards {
  width: 100%;
  line-height: 5px;
  text-align: left;
}
.trainingCard {
  margin-bottom: 10px;
  background-color: white;
}
.trainingText {
  margin-left: 35px;
}
.errorText {
  font-weight: 'bold';
  color: map-get($colors, 'red-hover');
}
.linkText {
  cursor: pointer;
  text-decoration: underline;
  color: map-get($colors, 'blue');
}
.trainingRequiredButton {
  background-color: map-get($colors, 'red-light') !important;
  border: 1px solid map-get($colors, 'red-hover') !important;
  color: map-get($colors, 'red-hover') !important;
  box-shadow: 0px 0px 1px map-get($colors, 'red-hover');
  font-weight: bold;
}

.button {
  width: 147px;
}
.adminContainer {
  background: #f1f2f2;
  margin: 15px;
  padding: 10px;

  .adminInfo {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    left: 10px;
  }
}

.specialGrid {
  grid-template-columns: 16fr 1fr;
}

.removeImage {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
}
