.correctAnswers {
  display: flex;
  flex-direction: column;
  text-align: right;

  h4 {
    margin-top: 0;
  }
}

.label {
  font-weight: bold;
}

.subHeader {
  display: flex;

  .subHeaderText {
    font-size: 16px;
    text-align: left;
    margin-top: 0px;
    padding: 5px;
  }

  .imageDiv {
    margin-right: 20px;
    width: 25px;
    height: 25px;

    img {
      width: 25px;
      height: 25px;
    }
  }
}
