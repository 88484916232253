.photoEvidenceContainer {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.photoEvidenceContainer-noContent {
  margin: 17.5% auto 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}
