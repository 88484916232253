@import '../../../styles/colors.scss';

.main {
  display: flex;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.pictures {
  width: 215px;
  height: 215px;
  margin-right: 20px;
  position: relative;
}

.pictures img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.pictureInfo {
  background: #5f5f5f;
  mix-blend-mode: multiply;
  position: relative;
  bottom: 30px;
  width: inherit;
  height: 26px;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  @include color('white', $shadow: true);
}

.button {
  width: 142px;
  white-space: nowrap;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.itemDescription {
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  margin-left: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
