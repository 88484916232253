@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.dayOfWeekButtons {
  display: flex;
  justify-content: space-between;
  max-width: 460px;
  $dowButtons: &;

  > div {
    height: 60px;
    width: 60px;
    border: 2px solid map-get($colors, 'grey-stroke');
    border-radius: 5px;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include color('grey');
    cursor: pointer;

    @include atContext($dowButtons, ':not(.disabled)') {
      &:hover {
        background: map-get($colors, 'white-hover');
      }
    }
    @include atContext($dowButtons, '.disabled') {
      background: map-get($colors, 'grey-bkgd');
      border-color: map-get($colors, 'grey-bkgd');
      cursor: not-allowed;
    }

    &.selected {
      @include color(
        'blue',
        $text: true,
        $shadow: true,
        $background: true,
        $border: 'blue'
      );
      @include atContext($dowButtons, ':not(.disabled)') {
        &:hover {
          background: map-get($colors, 'blue-hover');
          border-color: map-get($colors, 'blue-hover');
        }
      }
    }

    &.error {
      background: map-get($colors, 'red-light');
      border-color: map-get($colors, 'red-hover');
      color: map-get($colors, 'red-hover');
    }
  }

  .fromList {
    border: 1px solid map-get($colors, 'grey-hover') !important;
    margin: 5px;
  }
}
