@import '../../styles/colors.scss';

.signatureCard {
  .imageUrl {
    width: 800px;
    height: 200px;
    object-fit: contain;
  }

  .label {
    color: map-get($colors, 'red');
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 5px;
    margin-left: 5px;
  }
}
