@import '../../styles/colors.scss';

$othersCommentColor: map-get($colors, 'green');
$myCommentColor: map-get($colors, 'blue');

.commentGroup {
  text-align: left;
  margin: 10px 0 0 25px;
  position: relative;
}

.comment {
  color: $othersCommentColor;
  display: flex;
  margin: 15px 0;
  position: relative;

  .content {
    flex-grow: 1;

    .authorRow {
      display: flex;
      justify-content: flex-start;
    }

    .text {
      background-color: rgba($othersCommentColor, 0.2);
      border: 1px solid $othersCommentColor;
      border-radius: 5px;
      color: map-get($colors, 'black');
      margin: 5px 0;
      padding: 5px;

      .replyName {
        color: $othersCommentColor;
        font-weight: bold;
      }
    }

    .actionTimeRow {
      display: flex;
      justify-content: space-between;

      .replyBlock {
        background: transparent;
        border: none;
        color: inherit;
        cursor: pointer;
        display: inline;
        font-size: inherit;
        margin: 0;
        padding: 0;
        text-decoration: underline;

        .reply {
          fill: $othersCommentColor;
          margin-right: 0.25em;
        }
      }

      .timeBlock {
        order: 10;
      }
    }
  }

  .collapser {
    align-items: center;
    display: flex;
    flex: 0 0 50px;
    justify-content: center;

    .chevron {
      cursor: pointer;
      fill: $othersCommentColor;
      overflow: visible;

      &.closed {
        transform: rotate(180deg);
      }
    }
  }

  &.myComment {
    .content {
      color: $myCommentColor;

      .authorRow {
        justify-content: flex-end;
      }

      .text {
        background-color: rgba($myCommentColor, 0.2);
        border: 1px solid $myCommentColor;

        .replyName {
          color: $myCommentColor;
        }
      }

      .replyBlock {
        order: 20;

        .reply {
          fill: $myCommentColor;
        }
      }
    }

    .collapser .chevron {
      fill: $myCommentColor;
    }
  }
}

.nestedComments {
  overflow: hidden;
  margin-left: 30px;
  transition: height 300ms ease-out;

  &.hidden {
    display: none;
  }
}

.threadLine {
  position: absolute;

  &.top {
    border-left: 2px solid map-get($colors, 'grey-stroke');
    border-top: 2px solid map-get($colors, 'grey-stroke');
    width: 6px;
  }

  &.bottom {
    border-bottom: 2px solid map-get($colors, 'grey-stroke');
    width: 36px;
  }

  &.hidden {
    display: none;
  }
}

.textBoxContainer {
  height: 5rem;
  margin-top: 2rem;
  overflow: hidden;
  padding: 0 50px 0 25px;
  position: relative;
}

.textBox {
  left: 0;
  margin: 0 50px 0 25px;
  padding: 0;
  position: absolute;
  right: 0;
}

#replyName {
  color: map-get($colors, 'blue');
  font-weight: bold;
  margin: 8px;
  padding: 2px;
  position: absolute;

  p {
    display: inline;
  }

  .deleteReply {
    color: initial;
    margin: 0 2px;
    opacity: 33%;

    &:hover {
      cursor: pointer;
      opacity: 100%;
    }
  }
}

.submitButton {
  align-items: flex-end;
  margin-top: 0 !important;
  margin-left: auto;
  margin-right: 50px;
}
