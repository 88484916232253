@import '../../styles/colors.scss';

.fileDownloader {
  position: relative;
  margin: 0 20px;
  flex: 0 0 33.33%;

  .downloader {
    width: 140px;
    height: 140px;
    cursor: pointer;
    object-fit: contain;
  }

  .deleteAttachment button {
    position: absolute;
    top: 10px;
    left: 120px;
    font-size: 8px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }

  .taskDelete button {
    position: absolute;
    top: -5px;
    left: 80px;
    font-size: 8px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }

  .listDownload {
    width: 60px;
    height: 60px;
  }

  .fileName,
  .shortenName {
    font-size: 11px;
    text-decoration: none;
    color: map-get($colors, 'black');
  }

  .shortenName {
    text-overflow: ellipsis;
    flex: 1;
  }
}
