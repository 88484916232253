@import '../../../styles/colors.scss';

.button,
.filterButton {
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid;
  border-color: map-get($colors, 'grey-stroke');
  min-height: 40px;
  min-width: 143px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.shrink {
    min-width: 0;
  }

  > .image {
    max-width: 16px;
    max-height: 16px;
  }

  > svg {
    max-height: 16px;
  }

  > div {
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
    text-shadow: none;
  }

  &:disabled {
    background: map-get($colors, 'white-hover');
    border: none;
    cursor: not-allowed;
  }

  @include colorStyling(
    $background: true,
    $hover: true,
    $disabled: true,
    $text: true,
    $border: true,
    $svgFillAsText: true
  );
}
