.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.85);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundOverlay {
  overflow: hidden;
  position: absolute;
  z-index: 9998;
  filter: blur(2px);
}

.modalHide {
  display: none;
}

.blueHeader {
  background: #008284;
}

.redHeader {
  background: #c74846;
}

.greenHeader {
  background: #2f8738;
}

.modalBody {
  width: 600px;
  border-radius: 10px;
  padding: 20px 0;
  max-height: 90vh;
}

.modalBody.modalWide {
  min-width: 66vw;
  width: auto;
  max-width: 90vw;
}
.modalBody.modalMedium {
  min-width: 57vw;
  max-width: 77vw;
}

.modalBody-header {
  min-height: 48px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}

.modalBody-header--text {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin: 10px;
}

.modalBody-header--X {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  border-radius: 0px 10px 0px 0px;
}

.blueHeader .modalBody-header--X:hover {
  background: #005d5e;
}

.redHeader .modalBody-header--X:hover {
  background: #a13a38;
}

.greenHeader .modalBody-header--X:hover {
  background: #226128;
}

.modalBody-header img {
  height: 28px;
  width: 28px;
}

.modalBody-content {
  background: #fff;
  padding: 0px;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  text-align: left;
  max-height: calc(90vh - 48px - 56px);
}

.modalMediumTall {
  height: 300px;
}
.modalTall {
  height: 400px;
}
.modalTaller {
  height: 800px;
}

.modalBody-content--hideButtons {
  background: #fff;
  padding: 0px;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  text-align: left;
  border-radius: 0px 0px 10px 10px;
  max-height: calc(90vh - 48px);
}

.modalBody-content .input {
  padding-left: 0;
}

.modalBody-footer {
  min-height: 56px;
  background: #ffffff;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.modalBody-footer .button {
  margin: 0px 10px;
}

.modalBody-footer-cannotSubmit {
  cursor: not-allowed !important;
}
