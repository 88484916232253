.reportActivityCard {
  height: fit-content;
  width: 180px;
  margin: 10px auto;
}

.reportActivityCard-createdDate {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #5f5f5f;
  padding: 0 7.5px;
}

.reportActivityCard-header {
  width: 178px;
  height: 26px;
  margin: 0;
  border-radius: 10px 10px 0px 0px;
  background: #f1f2f2;
}

.reportActivityCard-headerText {
  padding-top: 5px;
}

.reportActivityCard-header.newIncidentOwner {
  width: 85%;
  background: #008284;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 10px;
  margin-left: 15px;
}

.reportActivityCard-header.blueHeading {
  width: 50%;
  background: #008284;
  border-radius: 10px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.reportActivityCard-header.greenHeading {
  width: 50%;
  background: #2f8738;
  border-radius: 10px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.reportActivityCard-header.redHeading {
  width: 50%;
  background: #c74846;
  border-radius: 10px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.reportActivityCard-header.greyHeading {
  max-width: 85%;
  background: #5f5f5f;
  border-radius: 10px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
  margin: 0px;
}

.reportActivityCard-content {
  height: fit-content;
  border: 1px solid #949494;
  border-radius: 10px;
}

.reportActivityCard-contentSummary {
  height: 55px;
  width: 178px;
  background: #f1f2f2;
  border-bottom: 1px solid #949494;
  padding: 5px;
}

.reportActivityCard-contentSummary--noResource {
  height: 20px;
  width: 178px;
  background: #f1f2f2;
  border-bottom: 1px solid #949494;
  padding: 5px;
}

.reportActivityCard-reportContainerBlue {
  border: 1px solid #008284;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #008284;
}

.reportActivityCard-reportContainerRed {
  border: 1px solid #c74846;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #c74846;
}

.reportActivityCard-reportContainerGreen {
  border: 1px solid #2f8738;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #2f8738;
}

.reportActivityCard-reportContainerGrey {
  border: 1px solid #5f5f5f;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #5f5f5f;
}

.reportActivityCard-reportContainerText {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  padding-top: 9px;
}

.reportActivityCard-assignee {
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: center;
}

.reportActivityCard-assignee .profileBadge {
  width: 32px;
  height: 32px;
}

.reportActivityCard-assigned div.name.name span {
  font-style: italic;
  font-size: 14px;
}

.reportActivityCard-assigneeBlue {
  background: #008284;
  border-radius: 10px;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 74px;
  height: 15px;
  margin: auto;
  position: relative;
  top: -7.5px;
}
