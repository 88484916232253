@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.trainingTemplate {
  display: flex;
  margin-bottom: 0;

  .fileUpload {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: -100;
    opacity: 0;
    width: 155px;
    height: 36px;
  }

  .attachmentContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    box-shadow: inset map-get($colors, 'black-shadow');
    height: fit-content;
    margin-top: 20px;
  }
}

.buttonContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 20px 10%;
  @include media('<=desktop') {
    grid-template-columns: repeat(3, minmax(20%, 1fr));
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  @include media('<=tablet') {
    grid-template-columns: repeat(3, minmax(10%, 0.5fr));
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.button {
  min-width: 50px;
  flex: 1 1 20%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0px !important;
  @media (max-width: 500px) {
    justify-content: center;
  }
}
.buttonText {
  font-size: 18px;
  font-weight: 700;
  word-wrap: break-word;
  margin: 5px;
  text-align: left;
  white-space: normal;
  @media (max-width: 500px) {
    display: none;
  }
}
.buttonSubtext {
  font-size: 16px;
  font-weight: 300;
  word-wrap: break-word;
  margin: 5px;
  text-align: left;
  white-space: normal;
  @media (max-width: 500px) {
    display: none;
  }
}
.imageStyles {
  justify-self: flex-start;
  border-radius: 50px;
  padding: 8px;
  margin: 15px 10px 15px;
}
.tooltip {
  @media (min-width: 500px) {
    display: none;
  }
}
.notStarted {
  background-color: map-get($colors, 'blue');
}
.inProgress {
  background-color: map-get($colors, 'yellow');
}
.completed {
  background-color: map-get($colors, 'green');
}
