@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sectionLabel {
  margin-left: 20px;
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 27.6px;
}

.incidents {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 0px 50px;
}
.grow {
  min-width: 100%;
}

.noIncidents {
  background-color: #fff;
  border-radius: 5px;
  box-shadow:
    0 2px 3px hsla(0, 0%, 4%, 0.1),
    0 0 0 1px hsla(0, 0%, 4%, 0.1);
  color: #4a4a4a;
  padding: 1.5rem 1.25rem 3rem;
  margin-top: 2rem;
  margin-left: 3rem;

  @include media('<=tablet') {
    width: 90vw;
    margin-left: 0;
  }
}
.buttonContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(20%, 1fr));
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px;
  @include media('<=desktop') {
    grid-template-columns: repeat(3, minmax(20%, 1fr));
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  @include media('<=tablet') {
    grid-template-columns: repeat(3, minmax(10%, 0.5fr));
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.button {
  min-width: 50px;
  flex: 1 1 20%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0px !important;
  @media (max-width: 500px) {
    justify-content: center;
  }
}
.buttonText {
  font-size: 18px;
  font-weight: 700;
  word-wrap: break-word;
  margin: 5px;
  text-align: left;
  white-space: normal;
  @media (max-width: 500px) {
    display: none;
  }
}
.imageStyles {
  justify-self: flex-start;
  border-radius: 50px;
  padding: 8px;
  margin: 15px 10px 15px;
}
.selected {
  background-color: map-get($colors, 'white');
  svg {
    fill: map-get($colors, 'blue') !important;
  }
}
.tooltip {
  @media (min-width: 501px) {
    display: none !important;
  }
  z-index: 9999 !important;
}
.notSelected {
  background-color: map-get($colors, 'blue');
}
