@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.footer {
  width: 100%;
  height: $footerHeight;
  background: #fff;
  box-shadow: 0px ($footerShadowHeight * -1) $footerShadowHeight
    rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonFooter :global(.input) {
  margin: 0;
  button {
    margin: 0;
  }
}
