@import '../../../styles/colors.scss';

.diagramCard {
  .diagramCardSubHeader {
    text-align: left;
  }

  .files {
    display: flex;
    flex-flow: row wrap;
  }
}
