@import '../../styles/colors.scss';

.container {
  font-family: Helvetica, sans-serif;

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    padding: 40px;
    padding-top: 0;
  }

  .search {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    gap: 20px;
    margin: 10px;
    height: 44px;
    width: 780px;
  }
}

.bottom {
  justify-content: flex-end;
}

.addButton,
.deleteButton {
  width: 200px;
  height: 40px;
}

.addButton {
  left: calc(25% - 200px / 2);
  position: relative;
}

.image {
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
  margin-left: 0px;
}

.leaderboardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.noCardMessage {
  justify-content: center;
  align-items: center;

  .noCard,
  .createMessage {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: map-get($colors, 'grey-stroke');
  }

  .createMessage {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
}
