@import '../../../styles/colors.scss';

.button {
  border-radius: 10px 36px 36px 10px;
  cursor: pointer;
  border: 1px solid;
  border-color: map-get($colors, 'blue');
  background-color: map-get($colors, 'white');
  height: 36px;
  width: fit-content;
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px;

  &:hover {
    background: map-get($colors, 'white-hover');
  }
}

.leftCircle {
  border-radius: 36px 10px 10px 36px;
}

.circle {
  background-color: map-get($colors, 'blue');
  border-radius: 50%;
  height: 36px;
  width: 36px;
  color: map-get($colors, 'white');
  font-size: 30px;
  line-height: 36px;
}

.buttonText {
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  text-shadow: none;
  color: map-get($colors, 'blue');
  padding: 10px 10px;
}
