@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.container {
  margin-bottom: 2em;
}

.timeFrame,
.buttons {
  align-items: center;
  transform: translateY(-15px);

  .button {
    button {
      border-width: 1px;
      border-color: map-get($colors, 'grey-stroke');
      border-radius: 5px;
      color: map-get($colors, 'black');
      min-height: 0;
      padding: 7px 12px 7px 12px;
      box-shadow: 0px 0px 2px 1px map-get($colors, 'white-box-shadow');
      width: 15vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      font-size: 16px;
    }

    &.selected button {
      background-color: map-get($colors, 'blue');
      color: map-get($colors, 'white');
      font-weight: bold;
      border-color: map-get($colors, 'blue');
    }
  }
}

.timeFrame {
  padding-top: 1em;
}

.empty {
  padding: 1em;
  font-weight: bold;
  font-size: 18px;
}

.incident,
.task {
  padding: 1em;
  border-bottom: 1px solid #{map-get($colors, 'grey-stroke')};
  cursor: pointer;

  &:hover {
    background-color: map-get($colors, 'grey-bkgd');
  }
}

.incident {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .donutText {
    font-size: 14px;
    padding-bottom: 0.25em;
  }

  > div:not(.incidentInfo) {
    margin: 0 1em;
  }

  .incidentInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: auto;

    > * {
      margin: 3px 0;
    }

    .number {
      font-weight: bold;
      padding-bottom: 0.33em;
    }
    .group {
      font-style: oblique;
      font-size: 14px;
    }
    .reported {
      font-size: 14px;
      text-align: left;
    }
    .categories {
      display: flex;
      flex-direction: row wrap;
    }
    .claimStage {
      text-align: left;
    }
  }

  .donutContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .donutStage {
      margin: 5px;
    }

    .donut {
      width: 100px;
      height: 100px;
      margin-top: 0.25em;

      &.pastDue {
        color: map-get($colors, 'red');
      }

      &.started {
        color: map-get($colors, 'blue');
      }

      &.complete {
        color: map-get($colors, 'green');
      }

      span {
        font-size: 12px;
      }
    }
  }
}

.task {
  text-align: left;
  color: map-get($colors, 'blue');
  display: flex;
  justify-content: space-between;

  .labels {
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 0.33em;
    display: inline-flex;

    > span {
      margin-left: 0.33em;
      &:not(:first-of-type):not(:nth-child(2))::before {
        content: '|';
        margin-right: 0.33em;
        font-style: normal;
      }

      &.long {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.taskType {
        font-weight: bold;
        margin-left: 0;
        &::before {
          content: '\2022';
          margin-right: 0.25em;
        }
      }
    }
  }

  .left {
    display: inline-flex;
    flex-direction: column;
    min-width: 0;
    align-self: center;
  }
  .right {
    display: flex;
  }

  &.pastDue {
    color: map-get($colors, 'red');
  }

  .taskLocation {
    margin-left: 0.33em;
    font-style: italic;
  }

  .dueDate {
    display: flex;
    flex-direction: column;
    border-left: 3px solid;
    padding-left: 0.33em;
    > span:first-child {
      font-weight: bold;
    }
  }

  .leftSpace {
    margin-left: 1em;
  }
}

.assignees {
  display: flex;
  color: map-get($colors, 'black');
  font-weight: bold;
  align-items: center;

  .aText {
    margin-right: 0.66em;
  }
  .overflow {
    margin-left: 0.66em;
  }
}

.itemGraph {
  margin-left: auto;
  margin-right: auto;

  .observationCell {
    fill: map-get($colors, 'blue-light');
    stroke: map-get($colors, 'blue');
  }

  .incidentCell {
    fill: map-get($colors, 'yellow-light');
    stroke: map-get($colors, 'yellow');
  }
}

.card.card > h2 {
  margin-bottom: 5px;
}

.pagination {
  margin-top: 20px;
}

.chartContainer {
  margin: 0 auto;
  max-width: 500px;
}

.customCardChart {
  margin: 40px;
  width: 50%;
  height: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  display: flex;
  align-items: center;

  svg {
    fill: map-get($colors, 'blue');
  }

  .rightChevron svg {
    transform: rotate(180deg);
  }

  .rightChevron,
  .leftChevron {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled svg {
      fill: map-get($colors, 'grey-stroke');
    }
  }

  .leftChevron {
    padding-right: 30px;
  }

  .rightChevron {
    padding-left: 30px;
  }
}
