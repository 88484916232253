@import '../../styles/colors.scss';
.signatureBox {
  padding: 10px;
  border: 2px solid map-get($colors, 'grey-stroke');
  border-radius: 10px;
}
.signatures {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;
  .input.input,
  :global(.input.input) {
    margin-top: 0;
  }
  .input img {
    align-self: center;
  }
}
.twoColumn {
  grid-template-columns: 1fr 1fr;
}
.threeColumn {
  grid-template-columns: 1fr 1fr 1fr;
}
.qrCode {
  justify-self: center;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
