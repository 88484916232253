@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.login {
  width: 450px;
  a {
    font-family: Helvetica;
    font-style: italic;
    color: map-get($colors, 'green');
    font-size: 14px;
  }
  label {
    color: map-get($colors, 'green');
  }
  h2 {
    font-size: 24px;
    color: map-get($colors, 'green');
    border-bottom: 2px solid map-get($colors, 'green');
  }
}

.loginAndroidBadge {
  width: 170px;
}

.loginFlexbox {
  height: 100%;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.loginWholePage {
  height: 100%;
  background-color: map-get($colors, 'white');
  display: flex;
  align-items: center;
  justify-content: space-around;
}
