@import '../../styles/colors.scss';

.label {
  min-height: 21px;
  max-height: fit-content;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  display: block;
  @include color('white-shadow', $shadow: true);
  cursor: pointer;
  margin-bottom: 0.33rem;
}

.isRequired {
  color: map-get($colors, 'red');
  margin-right: 0.25rem;
}

.richText {
  min-height: 21px;
  max-height: fit-content;
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  display: flex;
  @include color('white-shadow', $shadow: true);
  cursor: pointer;
  margin-bottom: 0.33rem;

  .isRequired {
    margin-top: 20px;
  }
}

.input {
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;

  &:focus {
    outline: none;
  }

  &:disabled {
    filter: opacity(50%);
  }

  .na {
    justify-content: flex-start;
  }

  &.inline {
    display: inline-flex;
  }
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  .input.input,
  :global(.input.input) {
    margin-top: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  .input.input,
  :global(.input.input) {
    margin-top: 0;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.space {
    justify-content: space-evenly;
  }
}

.caution {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  margin-left: 5px;
}

.inputWarning {
  display: flex;
  color: map-get($colors, 'red');
  text-align: left;
  margin-top: 5px;
  margin-left: 5px;
  align-items: center;
}

.warningText {
  margin-top: 4px;
}

.toolTip {
  max-width: 300px;
  font-size: 16px !important;
  text-align: left;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 0px 5px map-get($colors, 'white-box-shadow');
  opacity: 1 !important;
  background: map-get($colors, 'grey') !important;
  overflow: scroll;
  z-index: 9999 !important;
}

/* Is there a better way? */
.input + .row,
.input + .twoColumn,
.input + .input,
.row + .input,
.row + .twoColumn,
.row + .row,
.twoColumn + .input,
.twoColumn + .row,
.twoColumn + .twoColumn {
  margin-top: 20px;
}
