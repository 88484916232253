@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.snapshotColumns {
  grid-template-columns: 1fr 2fr;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  text-align: left;
}

.sectionHeader,
.userInfoHeader {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.sectionHeader,
.subSectionHeader {
  text-align: left;
  padding-bottom: 10px;

  .subSectionHeader,
  .incidentInfo {
    font-size: 18px;
    line-height: 21px;

    .incidentInfo {
      padding-bottom: 5px;
    }
  }
}

.userInfoContainer {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.employeeBadge {
  padding: 20px 25px 5px 25px;
  align-self: center;
}

.divider {
  position: static;
  width: 280px;
  height: 2px;
  margin: 20px 0px;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
}

.userInfoLabel,
.locationLabel,
.userInfoValue {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin: 0 10px 10px 0;
}

.userInfoValue {
  font-weight: normal;
}

.locationLabel {
  text-align: left;
}

.userLocationBubble {
  color: #{map-get($colors, 'white')};
  background: #008284;
  border-radius: 20px;
  width: fit-content;
  padding: 3px 10px;
  margin: 2.5px 0px;
  flex-direction: row;
  display: flex;
}

.userInfoColumns {
  gap: 0px;

  .userInfoLeftColumn,
  .userInfoRightColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .userInfoRightColumn {
    align-items: flex-start;
  }
}

.sectionColumn {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: fit-content;
  margin-left: 20px;
  margin-bottom: 20px;
}

.expiringContainer {
  width: 100px;
  height: 100px;
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .expiringNumber {
    font-weight: bold;
    font-size: 48px;
    line-height: 55px;
    display: flex;
  }

  .expiringLabel {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }
}

.list {
  padding-left: 10px;
  margin-top: 0px;

  li {
    text-align: left;

    > span {
      color: black;
      font-size: 18px;
      line-height: 21px;
    }
  }
}

.incidentContainer {
  background-color: #{map-get($colors, 'white')};
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  flex-direction: row;
  display: flex;
  align-content: center;

  .incidentSvgs {
    display: flex;
    align-items: center;
    margin-left: auto;
    align-self: center;
  }
}

.noData {
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #{map-get($colors, 'grey')};
}

.progressBar,
.progressBarFilling {
  border: 1px solid #{map-get($colors, 'grey-stroke')};
  box-sizing: border-box;
  border-radius: 20px;
  background: #{map-get($colors, 'grey-bkgd')};
  height: 25px;
  margin: 5px 0px;
  position: relative;
}

.progressBarFilling {
  background: #{map-get($colors, 'blue')};
  margin: 0px;
  height: 100%;
  position: absolute;
  border: 1px solid #{map-get($colors, 'blue')};
}

.margin {
  margin: 0px 10px;
}

.analyticText,
.analyticBoldText {
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.analyticBoldText {
  font-weight: bold;
}

.tooltip {
  background-color: #{map-get($colors, 'grey-bkgd')} !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2)) !important;
  font-size: 18px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: auto;
  white-space: pre-wrap;
}
