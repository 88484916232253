@import '../../styles/colors.scss';

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  circle {
    fill: map-get($colors, 'blue');
  }
}
