@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.topNav {
  height: $topNavHeight;
  text-align: left;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  background-color: $topNavColor;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include media('<=tablet') {
    display: block;
  }

  @include media('>tablet') {
    display: flex;
  }
}

.leftSide {
  display: flex;
}

.rightSide {
  display: flex;
  padding-right: 10px;
  flex-direction: row;

  @include media('<=tablet') {
    display: none;
  }
}

.pageActions {
  grid-column: 3/4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  position: relative;
  z-index: 10;

  div {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: white;
    cursor: pointer;
  }

  .chevron {
    rotate: 90deg;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s;
  }
  .dropdown {
    height: fit-content;
    position: absolute;
    right: 0;
    background-color: white;
    top: 100%;
    min-width: 250px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
    cursor: pointer;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .dropdownOption {
      padding: 12px;
      &:hover {
        background-color: map-get($colors, 'white-hover');
      }
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      border-radius: 5px;
      margin: 5px;

      @include colorStyling($text: true);
    }
  }
}
.logoContainer {
  @include color('white', $background: true, $border: 'white');
  overflow: hidden;
  height: 50px;
  width: 165px;
  border: {
    width: 1px;
    style: solid;
    radius: 100px;
  }

  @include media('<=tablet') {
    margin: 3px 20px 0 10px;
    display: flex;
  }

  @include media('>tablet') {
    margin: 3px 60px 0 10px;
  }

  &:hover {
    border: 1px solid map-get($colors, 'white-hover');
  }

  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
}

.locationContainer {
  display: flex;
  margin-top: 3px;
}

.locationContainerText {
  @include color('green', $text: true, $shadow: true);
  font-weight: bold;
  font-size: 18px;
  margin: 15px 5px 0 0;
}

.locationButton button > div {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #2f8738;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
