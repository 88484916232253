.claimCard {
  text-align: left;
  font-size: 18px;
  line-height: 21px;

  .claimLabel {
    font-weight: bold;
  }

  .stackedText {
    display: flex;
    flex-direction: column;
  }
}

.deathDate {
  margin-top: 0 !important;
}

.thirdPartyHeader {
  text-align: left;
  font-size: 22px;
}
