.location-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  height: 128px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 1.5;
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  color: #363636;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: calc(0.375em - 1px) 0.75em;
  text-align: center;
  text-decoration: none;
}

.location {
  min-height: 8pc;
  width: 270px;
  color: #008284;
  margin: 15px;
  border: 4px solid #008284;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.location:hover,
.longTermLocation:hover {
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.25);
}

.location.location--noAccess:hover,
.longTermLocation.location--noAccess:hover {
  box-shadow: none;
}

.location.location--noAccess,
.longTermLocation.location--noAccess {
  justify-content: flex-start;
}

.longTermLocation {
  min-height: 8pc;
  width: 270px;
  color: #fff;
  margin: 15px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 700;
  background-color: #008284;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.locationName {
  position: relative;
  overflow-wrap: break-word;
  width: 100%;
  vertical-align: bottom;
  top: -43px;
}

.longTermLocation .customTheme,
.location .customTheme {
  padding: 0 10px !important;
  text-align: center;
}

.location-newProject {
  position: absolute;
  bottom: 7px;
  right: 7px;
}

.location-newProject--whiteWords,
.location-newProject--blueWords {
  position: relative;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  bottom: 5px;
  display: inline-block;
  bottom: 9px;
  right: 10px;

  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.location-newProject:hover > .location-newProject--whiteWords,
.location-newProject:hover > .location-newProject--blueWords {
  opacity: 1;
}

.location-newProject--blueWords,
.location-existingProjects--blueWords {
  color: #008284;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.location-existingProjects {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border-radius: 0 0 5px 5px;
  height: 27px;
}

.location-existingProjects.blue {
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #008284;
}

.location-existingProjects.white {
  background-color: #fff;
}

.location-existingProjects--whiteWords {
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.location-existingProjects--triangle {
  position: relative;
  bottom: 10px;
}

.location-noAccess--longTerm {
  font-style: italic;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  position: relative;
  top: -32px;
}

.location-noAccess--shortTerm {
  font-style: italic;
  font-size: 14px;
  text-align: center;
  color: #008284;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  position: relative;
  top: -32px;
}

.locationIncidentOwnerPictureBlue,
.locationIncidentOwnerPictureWhite {
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  top: -31px;
  left: -125px;
  object-fit: fill;
  z-index: 30;
}

.locationIncidentOwnerPictureWhite {
  border: 2px solid #fff;
}

.locationIncidentOwnerPictureBlue {
  border: 2px solid #008284;
}

.assignIncidentOwner {
  border-radius: 0px 10px 0px 0px;
  width: inherit;
  height: 20px;
  font-size: 14px;
  position: relative;
  top: -68px;
  font-weight: bold;
  visibility: hidden;
}

.locationIncidentOwnerPictureWhite:hover + .assignIncidentOwner {
  background: #ffffff;
  color: #2ca0a3;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  visibility: visible;
}

.locationIncidentOwnerPictureBlue:hover + .assignIncidentOwner {
  background: #008284;
  color: #fff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  visibility: visible;
}

.projectModal {
  height: 400px;
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
}

.projectButtons {
  height: max-content;
}

.projectButtons .projectButton {
  overflow-x: hidden;
}

.projectButtons button div {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
