@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.dueDate {
  margin: 8px;
  color: white;
  background-color: map-get($colors, 'blue');
  border-radius: 15px;
  padding: 2px 5px;
  justify-content: center;
}
.overdue {
  background-color: map-get($colors, 'red');
}
.complete {
  background-color: map-get($colors, 'green');
}
.bold {
  font-weight: 700;
}
.left {
  justify-content: start;
}
