.accountSettings {
  display: flex;
  flex-flow: row wrap;
}

.leftColumn {
  flex-direction: column;
  margin-right: 20px;
  flex: 0.2;
  text-align: center;
}

.leftColumn img {
  max-width: 300px;
}

.rightColumnCompany {
  flex-direction: column;
  padding-right: 20px;
  margin-right: 20px;
  flex: 0.5;
}

.companyName {
  margin-top: 5px;
}

.smallHeader {
  font-size: 11px;
  letter-spacing: 1px;
  color: #999;
}

.smallHeaderStats {
  font-size: 11px;
  letter-spacing: 1px;
  color: #999;
  margin-bottom: 10px;
  margin-top: 15px;
}

.specialGrid {
  grid-template-columns: 16fr 1fr;
}

.removeImage {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
}

.addButton {
  width: 147px;
}
