@import '../../../styles/colors.scss';
.quizBox {
  border-radius: 5px;
  border: 1px solid map-get($colors, 'blue');
  margin-top: 10px;
  padding: 10px;
  align-items: center;
  width: fit-content;
  display: inline-flex;
  margin-right: 10px;

  .failedImage {
    width: 50px;
    height: 50px;
  }
  .passedImage {
    width: 50px;
    height: 50px;
    padding: 15px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 100px;
    background-color: map-get($colors, 'green');
  }
  .textContainer {
    margin-left: 10px;
    margin-top: 5px;
    .attempt {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }

    .score {
      font-size: 16px;
      text-align: center;
    }
  }
}
