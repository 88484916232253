.myDocumentList-reportSelection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.myDocumentList-button {
  height: 140px;
  width: 140px;
  background: #cce6e6;
  border: 2px solid #008284;
  border-radius: 5px;
  padding: 15px;
}

.myDocumentList-button:hover {
  height: 140px;
  width: 140px;
  background: #d9d9d9;
  border: 2px solid #008284;
  border-radius: 5px;
}

.myDocumentList-button > span {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #008284;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.myDocumentList-uploadForms {
  height: 60px;
  margin: 0 23px 8px;
}

.myDocumentList-premadeForms {
  height: 63px;
  margin: 0 22px 5px;
}

.myDocumentList-docTypeSelection {
  background: #ffffff;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 0px;
  margin-top: 10px;
}

.myDocumentList-docTypeSelection .radioButtons-row {
  flex-direction: column;
}

.myDocumentList-premadeFormsHeader {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
}
