@import '../../../styles/colors.scss';

.questionCard {
  display: flex;
  flex-flow: column;

  .questionTitle {
    color: map-get($colors, 'black');
    width: 100%;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }

  .image {
    max-width: 40px;
    max-height: 40px;
    &.disabled {
      filter: brightness(2) saturate(0.08) contrast(1.31);
      opacity: 50%;
    }
  }

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: -100;
    opacity: 0;
    width: 155px;
    height: 36px;
  }
}

.error {
  color: map-get($colors, 'red');
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  margin: 5px;
}

.selectedButton {
  width: 135px;
  height: 60px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .image {
    width: 47px;
    height: 47px;
  }

  .white {
    filter: brightness(0) invert(1);
  }

  @include colorStyling(
    $background: true,
    $hover: true,
    $disabled: true,
    $text: true,
    $border: true,
    $svgFillAsText: true
  );
}
