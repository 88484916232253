@import 'include-media';

$breakpoints: (
  phone: 400px,
  tablet: 800px,
  desktop: 1024px
);

$topNavHeight: 56px;

$outlineBorderRadius: 5px;

$headerHeight: 56px;
$bigHeaderHeight: 300px;
$footerHeight: 56px;
$footerShadowHeight: 8px;

$lockHeaderHeight: 71px;

$inputHeight: 38px;
$inputRadius: 5px;
$inputPadding: 7.5px;

@mixin atContext($context, $addition) {
  @at-root #{selector-replace(&, $context, $context + $addition)} {
    @content;
  }
}
