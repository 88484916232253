@import '../../styles/colors.scss';

.container {
  font-family: Helvetica, sans-serif;
  .favoritesWrapper {
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin: 19px;
    background: map-get($colors, 'white');
    grid-template-areas:
      header
      content;
  }
  .favoritesLabel {
    grid-area: header;
    text-align: left;
    color: map-get($colors, 'blue');
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 10px;
  }
  .favorites {
    grid-area: content;
  }
  .favorites,
  .other {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
  }
  .other {
    padding: 40px;
    padding-top: 0;
  }
  .addFavorites {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    padding: 20px 20px 0;
    max-width: 400px;
  }
}

.dashboard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid map-get($colors, 'grey-stroke');
  border-radius: 5px;
  padding: 20px;
  background: map-get($colors, 'white');

  .title {
    font-weight: bold;
    margin-top: 10px;
  }
}

.options {
  display: flex;
  justify-content: center;
  padding: 30px 0;

  .badge {
    background-color: map-get($colors, 'blue-light');
    font-size: 12px;
    line-height: 14px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: relative;
    top: 1px;
    left: 119px;
    padding: 2.5px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 50px;
      height: 50px;
      background: map-get($colors, 'blue');
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;

      &:hover {
        background: radial-gradient(
          map-get($colors, 'blue-hover') 20%,
          map-get($colors, 'blue') 50%,
          map-get($colors, 'white') 75%
        );
        cursor: pointer;
      }

      svg {
        fill: map-get($colors, 'white');
        stroke: map-get($colors, 'white');
        width: 20px;
        height: 20px;
      }
    }
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  .favorite {
    stroke: map-get($colors, 'yellow');
    &.isFavorite {
      fill: map-get($colors, 'yellow');
    }
    &:hover {
      fill: map-get($colors, 'yellow-light');
    }
  }

  .trash {
    stroke: map-get($colors, 'grey-stroke');
    fill: map-get($colors, 'grey-stroke');
    &:hover {
      stroke: map-get($colors, 'grey');
      fill: map-get($colors, 'grey');
    }
  }
}

.share {
  > div {
    border: 1px solid map-get($colors, 'blue');
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      margin-right: 25px;
      cursor: pointer;
      color: map-get($colors, 'red');
    }

    .shareOptions {
      margin-left: auto;
    }

    &.expired {
      border-color: map-get($colors, 'red');

      .expireMessage {
        margin-left: 10px;
        margin-top: 5px;
        color: map-get($colors, 'red');
      }
      .shareOptions {
        margin-left: 20px;
      }
    }
  }
}

.modalMessage {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin: 20px 0 0 10px;
  color: map-get($colors, 'blue');
}

.sharedInfo {
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  color: map-get($colors, 'blue');
}

.sharingWith {
  position: relative;
  top: 27px;
  left: 50%;
  color: map-get($colors, 'blue');
}

.bottom {
  justify-content: space-between;
}

.topMessage {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.noCardMessage {
  justify-content: center;
  align-items: center;

  .noCard,
  .createMessage {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: map-get($colors, 'grey-stroke');
  }

  .createMessage {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
}
