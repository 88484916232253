.reassignText {
  font-weight: bold;
  font-size: 20px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}

.stageDropdown {
  margin-top: 30px;
}

.titleDropdown {
  margin-bottom: 20px;
}

.infoText {
  padding: 10px;
}
