@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.container {
  display: flex;
  flex-direction: row;

  :global(.textbox) {
    flex-grow: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .currencyUnit {
    border: 1px solid map-get($colors, 'grey-stroke');
    background-color: map-get($colors, 'grey-bkgd');
    border-right: 0;
    border-radius: $inputRadius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: $inputHeight;
    line-height: $inputHeight;
    padding: 0 $inputPadding;
    &.disabled {
      border-color: map-get($colors, 'grey-bkgd');
    }
  }
}

.textbox {
  background: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'grey-stroke');
  box-shadow: inset 0px 0px 3px rgba(191, 191, 191, 1);
  border-radius: 0px 5px 5px 0px;
  min-height: 38px;
  font-size: 16px;
  resize: vertical;
  padding: 7.5px;
  display: block;
  width: 100%;
}

.textbox::placeholder {
  font-style: oblique;
}
