.trainingAttachmentViewer {
  display: flex;
  height: 100%;
  width: 100%;

  .main {
    width: 100%;
    align-items: center;

    .docViewer {
      height: 100%;
      width: 100%;
      margin: 0 20px;
    }

    .vidViewer {
      height: 100%;
      width: 100%;
      margin: 0 20px;
    }
  }
}
