@import '../../../styles/colors.scss';

.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .chartDivs {
    border: 1px solid map-get($colors, 'grey-stroke');
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .multipleStatistics {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .statistic,
    .corrected {
      font-size: 34px;
      font-weight: bold;
      border: 1px solid map-get($colors, 'grey-stroke');
      box-sizing: border-box;
      border-radius: 10px;
      width: 100px;
      height: 90px;
      margin: 5px;
      padding-top: 5px;

      .title {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        margin: 5px 0 0;
      }
    }

    .corrected {
      width: 150px;
    }

    .overallStatistic {
      padding-top: 25px;
    }
  }

  .timeSpent {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: normal;
      text-align: left;
    }
  }
}

.chartTitle {
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
}
