.container {
  :global(.konvajs-content) {
    border: 1px solid black;

    canvas {
      cursor: crosshair;
      background-color: white;
      margin-top: 10px;
    }
  }

  .footer {
    display: flex;
    flex-direction: row nowrap;
  }
}
