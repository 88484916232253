@import '../../styles/colors.scss';

.top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.noMargin {
  padding: 0;
  align-items: flex-end;
}

.checkbox {
  padding: 0;

  > div {
    padding: 0;
  }
}

.fullHeader {
  position: sticky;
  top: 0;
  z-index: 9;
}

.table {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  max-height: 70vh;
  margin: 10px 0;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  background:
    /* Shadow Cover TOP */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%)
      bottom,
    /* Shadow TOP */ linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0)),
    /* Shadow BOTTOM */ linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.33))
      bottom;
  background-repeat: no-repeat;
  background-position: 0;
  background-position-y: 96px, bottom;
  background-origin: content-box;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  table {
    border-collapse: separate;
    border-spacing: 0;
    font-size: 14px;
    white-space: nowrap;
    width: 100%;
    text-align: left;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 14px 10px;
    }
    th {
      background-color: white;
    }

    th.header {
      padding: 0;

      > div {
        padding: 14px 10px;
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        background-color: white;

        &.asc {
          border-top: 4px solid map-get($colors, 'blue');
          padding-top: 10px;
        }
        &.desc {
          border-bottom: 4px solid map-get($colors, 'blue');
          padding-bottom: 10px;
        }

        > svg {
          fill: map-get($colors, 'blue');
          height: 18px;
        }
      }
    }

    td,
    th {
      max-width: 500px;
      border-top: 1px solid map-get($colors, 'grey-stroke');
      border-bottom: 1px solid map-get($colors, 'grey-stroke');
      border-right: 1px solid map-get($colors, 'grey-stroke');
      &.filter {
        border-top: 0;
      }
      &.selector {
        padding: 5px;
        text-align: center;
        cursor: default;

        > div {
          display: inline-flex;
        }
      }
    }
    td {
      border-top: 0;
      background: none;
    }

    tr:nth-of-type(odd) td {
      background: map-get($colors, 'row-grey');
      background-clip: padding-box;
    }
  }

  &.hoverRow table tbody {
    tr:not(.noData) {
      cursor: pointer;
      &:hover td {
        background: map-get($colors, 'white-hover');
      }
    }
    tr.noData {
      font-size: 20px;
      font-variant: small-caps;
      text-align: center;
    }
  }
}

table th:first-child,
table td:first-child {
  border-left: 1px solid map-get($colors, 'grey-stroke');
  &.filterColumnFirst {
    border-left: 0;
  }
}

.error {
  width: 35%;
  height: 35%;
  margin: 8.5px;
}

.rowError {
  background: map-get($colors, 'red-light');
}
