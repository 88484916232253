@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.header {
  max-width: 100%;
  height: $bigHeaderHeight;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  .noCenter {
    grid-template-columns: 1fr 0 2fr;
  }

  /* Needed to stop the grid elements from growing. */
  min-width: 0;
  min-height: 0;

  .headerButton {
    margin: 0;
  }
}

.headerInfo {
  display: flex;
  min-width: 90%;
  overflow-y: hidden;
  min-width: 40%;
}

.backButton {
  width: 48px;
  height: 48px;
  margin: 15px;
  padding: 10px;
  background-color: map-get($colors, 'blue');
  border: none;
  border-radius: 5px;

  > img {
    width: 28px;
    height: 28px;
  }
}
.backButton:hover {
  background-color: map-get($colors, 'blue-hover');
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  :global(.input) {
    margin: 0;
  }
}

.infoBox {
  text-align: left;
  max-width: 100%;

  .title {
    overflow: hidden;
    line-clamp: 2;
    overflow-y: hidden;
    text-overflow: ellipsis;
    font-size: 48px;
    font-weight: 700;
    margin: 0;
  }
}

.pieBox {
  width: 160px;
  height: 160px;
  margin-right: 10px;
}

.instructions {
  overflow-wrap: break-word;
}
