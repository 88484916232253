.reportAttachment {
  min-height: 94px;
  max-height: 164px;
  width: 180px;
  margin: 20px auto;
  position: relative;
}

.reportAttachmentCard {
  min-height: inherit;
  max-height: inherit;
  width: inherit;
  margin: inherit;
  position: relative;
}

.reportAttachment:hover .reportAttachmentCard {
  background: rgba(0, 0, 0, 0.5);
  filter: blur(2px);
  border-radius: 10px;
}

.reportAttachmentCard-createdDate {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #808080;
  padding: 0 7.5px;
}

.reportAttachmentCard-content {
  background: #f1f2f2;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  width: 178px;
  margin: auto;
}

.reportAttachmentCard-image {
  width: inherit;
  height: 100px;
  border-radius: 10px 10px 0px 0px;
}

.reportAttachmentCard-image > img {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: contain;
}

.reportAttachmentCard-otherFile {
  background: #f1f2f2;
  border-radius: 10px 10px 0px 0px;
  width: 176px;
  height: 52px;
  display: flex;
  justify-content: flex-start;
}

.reportAttachment:hover .reportAttachmentCard-otherFile {
  background: rgba(0, 0, 0, 0.1);
}

.reportAttachmentCard-otherFile > img {
  width: 32px;
  height: 40px;
  padding: 5px 5px 0;
}

.reportAttachmentCard-otherFile--name {
  display: inline-block;
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-overflow: ellipsis;
  width: 130px;
  overflow-x: hidden;
  height: inherit;
  white-space: nowrap;
  text-align: left;
  margin-top: 19px;
}

.reportAttachment-badge {
  background: #ffffff;
  padding: 5px;
  border-top: 1px solid #c4c4c4;
  border-radius: 0px 0px 10px 10px;
}

.reportAttachment-badge div.name.name span {
  font-style: italic;
  font-size: 14px;
}

.reportAttachmentCard-buttons {
  display: none;
}

.reportAttachment:hover .reportAttachmentCard-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 36px;
  transform: translateY(-275%);
}

.reportAttachment:hover .reportAttachmentCard-buttons * {
  min-width: 75px;
  margin: auto;
}

.reportAttachment-removed {
  background: #c74846;
  border-radius: 10px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 105px;
}

.reportAttachment-removedByOwner {
  background: #c74846;
  border-radius: 0px 0px 10px 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 153px;
  margin: auto;
}
