.reportActionsContainer {
  border: 1px solid #008284;
  border-radius: 10px;
  width: 200px;
  height: 80vh;
  background-color: #fff;
  float: right;
}

.reportActions {
  height: calc(80vh - 120px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.reportActions-ddInput {
  margin-bottom: 10px;
}

.reportActions-completedTasks {
  font-size: 14px;
  line-height: 16px;
  color: #2f8738;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #2f8738;
  text-align: left;
  margin: 15px 10px 10px;
}

.reportActions-actionButtons {
  position: relative;
  bottom: 0px;
  width: 180px;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #008284;
  height: 80px;
}

.reportActions-actionButton {
  margin: 10px;
}

.reportActions-actionButton input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 155px;
  height: 36px;
  cursor: pointer;
}

.reportActions-assignee {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.reportActions-normal {
  font-size: 16px;
  line-height: 18px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.reportActions-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.reportActions-assignee--createdAt .reportActions-bold,
.reportActions-assignee--createdAt .reportActions-normal {
  text-align: right;
}

.reportLog.reportActions-bold,
.reportLog.reportActions-normal {
  text-align: left;
}

.reportActions-viewCommentNote {
  height: fit-content;
  padding: 10px;
  font-size: 16px;
  line-height: 18px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  background: #f1f2f2;
  border-radius: 2px;
}

.reportActions-createVerbage {
  font-size: 14px;
  line-height: 16px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
}

.reportActions-importantVerbage {
  color: #c74846;
  font-weight: bold;
}

.reportActions-removedActionItems {
  font-size: 14px;
  line-height: 16px;
  color: #c74846;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #c74846;
  text-align: left;
  margin: 15px 10px 10px;
}
