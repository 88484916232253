@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.container {
  display: flex;
  align-items: center;
}

.stageLabel {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  @include color('white-shadow', $shadow: true);
  margin-right: 5px;
  @include media('<=tablet') {
    display: none;
  }
}

.input {
  margin: 0;
  padding: 0;
  flex-direction: row;

  > span {
    white-space: nowrap;
    margin-bottom: 0;
    height: $inputHeight;
    line-height: $inputHeight;
    margin-right: 5px;
  }
}
