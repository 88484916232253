@import '../../../styles/colors.scss';

.blockText {
  background: #f1f2f2;
  font-size: 18px;
  line-height: 21px;
  @include color('white-shadow', $shadow: true);
  margin: 20px 0;
  text-align: left;
  padding: 15px;
}
