@import '../../../styles/colors.scss';
.tableContainer {
  overflow: scroll;
}
table {
  width: 100%;
  border-spacing: 0;
}
td,
th {
  border-top: 1px solid map-get($colors, 'grey-stroke');
  border-bottom: 1px solid map-get($colors, 'grey-stroke');
  border-right: 1px solid map-get($colors, 'grey-stroke');
}
th.plainHeader {
  padding: 1em;
  border-width: 2px;
  color: map-get($colors, 'white');
  background-color: map-get($colors, 'blue');
}
td {
  border-top: 0;
  border-left: 2px;
  background-color: map-get($colors, 'white');
}
.notEditingCell {
  padding: 1em;
  min-width: 12em;
  text-align: start;
}
.emptyCell {
  color: map-get($colors, 'grey-stroke');
  font-style: italic;
}
th.invalidHeader {
  background-color: map-get($colors, 'red-hover');
}
td.invalid {
  background-color: map-get($colors, 'red-light');
}
