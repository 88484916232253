@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

.container {
  $container: &;

  > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    height: inherit;
    @include atContext($container, '.noSpacing') {
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  button {
    width: 175px;
    height: 175px;
    margin: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 12px;
    border: 1px solid #{map-get($colors, 'blue')};
    font-size: 16px;
    color: map-get($colors, 'blue');
    &.error {
      border: 1px solid map-get($colors, 'red-hover');
      background: map-get($colors, 'red-light');
      color: map-get($colors, 'red-hover');
    }

    @include atContext($container, '.noSpacing') {
      &:not(:last-of-type) {
        margin-right: 0;
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-of-type) {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    > div {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      img,
      svg {
        width: 66.67%;
        height: 66.67%;
        object-fit: contain;
      }
      svg {
        fill: map-get($colors, 'blue');
        stroke: map-get($colors, 'blue');
      }
    }

    span {
      padding-top: 10px;
      white-space: normal;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:hover,
    &.active {
      @include colorStyling(
        $background: true,
        $text: true,
        $svgFillAsText: true,
        $svgStrokeAsText: true
      );
    }
  }
}

.bold {
  font-weight: 700;
}
