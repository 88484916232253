@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.tabs {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid grey;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  > div {
    font-size: 1.1em;
    padding: 10px;
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    background-color: map-get($colors, 'white');
    padding-bottom: 2px;
    cursor: pointer;

    &.selected {
      @include colorStyling($text: true, $invert: true);
      border: 0 solid;
      border-bottom-width: 3px;
      padding-bottom: 2px;
    }
  }
}
