@import '../../../styles/colors.scss';

.trainingTemplate {
  display: flex;
  margin-bottom: 0;

  .fileUpload {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: -100;
    opacity: 0;
    width: 155px;
    height: 36px;
  }

  .attachmentContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    box-shadow: inset map-get($colors, 'black-shadow');
    height: fit-content;
    margin-top: 20px;
  }
}
