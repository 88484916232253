@import '../../../styles/colors.scss';

.button {
  width: fit-content;
}

.numberOfDaysBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  width: fit-content;
  height: 44px;
  background: map-get($colors, 'white');
  border: 2px solid map-get($colors, 'blue');
  color: map-get($colors, 'blue');
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 5px;
}

.dateHeader {
  font-weight: bold;
  text-align: left;
}

.textbox {
  border-radius: 5px;
  width: 150%;
  min-height: 39px;
  text-align: center;
  font-size: 16px;
  border: 1px solid map-get($colors, 'grey-stroke');
}

.textbox + .react-datepicker__close-icon {
  right: -150px !important;
}

.dateBox,
.dateBoxOngoing {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  background: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'grey-stroke');
  box-sizing: border-box;
  border-radius: 5px;
  margin: 5px;
  height: 44px;

  span {
    display: flex;
    align-items: center;
    font-family: Helvetica;
    font-size: 18px;
    padding: 10px;
    &:hover {
      background: map-get($colors, 'white-hover');
    }
  }

  img {
    @include colorHover('red');
    border-radius: 0 4px 4px 0;
    height: 100%;
    padding: 10px;
  }
}

.dateBoxOngoing {
  border: 1px solid map-get($colors, 'red');

  span:hover {
    background: map-get($colors, 'red-light');
  }
}

.dash {
  padding: 10px;
  font-size: 18px;
}

.dateInputs {
  display: inline-flex;
}

/* maybe find a better way for the padding */
.warning,
.incidentDateWarning {
  color: map-get($colors, 'red');
  flex-direction: row-reverse;
  font-family: Helvetica;
  font-size: 16px;
  padding: 0px 100px 0px 10px;
}

.warning {
  padding-top: 5px;
}

.incidentDateWarning {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
