.reportSectionContainer-emailAssigneeText {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  background: #000000;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  width: 100%;
  height: fit-content;
  background: #fff;
  margin-bottom: 20px;
}

.reportSectionContainer-correctiveActionText {
  font-weight: bold;
  font-size: 24px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  width: 100%;
  margin: 20px;
}
