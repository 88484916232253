@import '../../styles/colors.scss';

.messageText {
  font-weight: 700;
  text-align: center;
  font-family: Helvetica;
  font-size: 24px;
  line-height: 28px;
}

.button {
  align-items: flex-start;
  padding: 0px;
  width: 200px;
  height: 38px;
}

.hireDateContainer {
  display: 'flex';
  flex-direction: 'column-reverse';
}

.datePicker {
  display: flex;
  align-items: flex-start;
  padding-top: 22px;

  :global(.SingleDatePicker .SingleDatePickerInput) {
    border-radius: 5px;
    padding: 1px;
    /* Sorry.  It's !important somewhere else. */
    width: auto !important;

    :global(.DateInput) {
      width: min-content !important;
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        width: 110px !important;
      }
    }
  }
}

.datePickerInvalid {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;

  :global(.SingleDatePicker .SingleDatePickerInput) {
    border-radius: 5px;
    padding: 1px;
    /* Sorry.  It's !important somewhere else. */
    width: auto !important;
    background-color: map-get($colors, 'red-light');
    border: 1px solid map-get($colors, 'red-hover');

    :global(.DateInput) {
      width: min-content !important;
      background-color: map-get($colors, 'red-light');
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        width: 110px !important;
        background-color: map-get($colors, 'red-light');
      }
    }
  }
}

.dateWarning {
  color: map-get($colors, 'red');
  flex-direction: row;
  font-family: Helvetica;
  font-size: 14px;
  display: flex;
}
