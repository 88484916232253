@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.questionButton {
  align-self: flex-end;
  width: 25%;
  cursor: pointer;
}

.previewButton {
  width: 25%;
}
.fileUpload {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: -10;
  opacity: 0;
  width: 1px;
  height: 1px;
}

.addButton {
  align-items: start;
  width: 100%;
}
.photoImage {
  scale: 1.5;
  margin-right: 12px;
  margin-bottom: 3px;
}
.percentBox {
  max-width: 15%;
}
.imageCard {
  display: flex;
  min-height: 94px;
  max-height: 100px;
  max-width: 100px;
  margin: 5px;
  img {
    max-width: 100px;
    max-height: 100px;
    padding: 5px 5px 0;
  }
}
.attachmentImageRemove {
  height: 36px;
  cursor: pointer;
  transform: translatex(-55%);
}
.answerRow {
  display: flex;
  width: 100%;
  min-height: 50px;
  margin-bottom: 5px;
  .answerButtons {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    .correctButton {
      min-width: 50%;
      margin: 0;
      flex-grow: 1;
    }
    .selectedAnswer {
      min-width: 60%;
      margin: 0;
      flex-grow: 1;
    }
    .answerPhotoButton {
      min-width: 45px;
      margin: 0;
    }
    .photoAttachedButton {
      min-width: 45px;
      margin: 0;
    }
    .answerDeleteButton {
      min-width: 48px;
      margin: 0;
    }
  }
}
.hoverTarget {
  $hoverHere: &;
  // .correctButton {
  //   @include atContext($hoverHere, ':hover') {
  //     opacity: 100%;
  //     display: block;
  //   }
  // }
  // .answerPhotoButton {
  //   @include atContext($hoverHere, ':hover') {
  //     opacity: 100%;
  //     display: block;
  //   }
  // }
  // .answerDeleteButton {
  //   @include atContext($hoverHere, ':hover') {
  //     opacity: 100%;
  //     display: block;
  //   }
  // }
}
.answerLabel {
  width: 75%;
}
.grow {
  width: 100%;
}

.removeImage {
  > img {
    height: 30px;
  }
}
.cameraWithAttach {
  min-width: 25px;
  min-height: 25px;
  margin-bottom: 0;
}
