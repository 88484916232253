@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.container {
  height: fit-content;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  $hoverHere: &;
  display: flex;
  align-items: center;

  .field {
    flex-grow: 1;
  }

  &.target {
    background-color: map-get($colors, 'grey-bkgd');
  }
}

.footer {
  border-radius: 0;
  border-top: 1px solid #c4c4c4;
  padding-top: 15px;
  margin-top: 10px;
}

.modal {
  max-height: 400px;
  overflow-y: auto;
  .options {
    display: flex;
    align-items: center;

    .option {
      flex-grow: 1;
    }

    .removeImage {
      > img {
        height: 30px;
      }
    }
  }
}

.redBackground {
  background-color: lightcoral;
}

.greenBackground {
  background-color: lightgreen;
}
