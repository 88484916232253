@import '../../styles/globals.scss';

.profileBadge {
  $badge: &;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  :global(.name) {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;

    @include atContext($badge, '.right') {
      text-align: right;
    }

    @include atContext($badge, ':not(.plain)') {
      .byline {
        font-size: 14px;
        font-style: italic;
      }

      .username {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .image,
  .loading,
  .numberCircle {
    &.space {
      margin-right: 10px;
    }
    width: 35px;
    height: 35px;
    @include atContext($badge, '.medium') {
      width: 46px;
      height: 46px;
    }
    @include atContext($badge, '.large') {
      width: 80px;
      height: 80px;
    }
    @include atContext($badge, '.xLarge') {
      width: 100px;
      height: 100px;
    }
    @include atContext($badge, '.xxLarge') {
      width: 206px;
      height: 206px;
    }
    @include atContext($badge, '.employeeSnapshot') {
      width: 150px;
      height: 150px;
    }
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
    @include atContext($badge, '.square') {
      border-radius: 5px;
    }
    @include atContext($badge, '.right') {
      order: 2;
      &.space {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
}

.numberCircle {
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .numberText {
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
  }
}
