@import '../../styles/colors.scss';

.card {
  min-height: 405px;
  padding: 0;
}

.card input[type='file'] {
  visibility: hidden;
}

.padding {
  padding: 20px;
}

.onePicture,
.pictureContainer {
  width: 100%;
  height: 272px;
  box-shadow: map-get($colors, 'black-shadow');
  background: #5f5f5f;
  display: flex;

  .photoImage {
    height: 100%;
    object-fit: contain;
    width: 90%;
  }
}

.pictureContainer {
  justify-content: space-between;
}

.onePicture {
  justify-content: space-evenly;
}

.pictureContainer .arrows {
  height: 272px;
  width: 42px;
  fill: white;
  &:hover {
    fill: map-get($colors, 'white-hover');
  }
  cursor: pointer;

  &:last-child {
    transform: rotate(180deg);
  }
}

.infoStyle {
  position: static;
  height: 16px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  @include color('red', $shadow: true);
  text-align: left;
}
