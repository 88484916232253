@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.tabs {
  display: flex;
  margin-top: 20px;
  padding-left: 40px;

  > div {
    padding: 10px;
    border-radius: $outlineBorderRadius;
    border: 1px solid;
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    background-color: map-get($colors, 'white');
    padding-bottom: 10px;

    > div {
      margin-right: 0.33em;
    }

    & ~ div {
      margin-left: 10px;
    }

    @include colorStyling($text: true, $invert: true);

    &:not(.selected) {
      cursor: pointer;
      border-bottom-color: transparent;
      &:hover {
        background-color: map-get($colors, 'white-hover');
      }
    }

    &.selected {
      border-bottom-width: 3px;
      padding-bottom: 7px;
    }
  }
}

.completedCard {
  background: map-get($colors, 'green-light') !important;
}

.card {
  height: fit-content;
  width: 960px;
  margin: 20px auto;
  padding: 20px;
  background: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'grey-stroke');
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px map-get($colors, 'white-box-shadow');

  .header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: map-get($colors, 'black');
    border-bottom: 2px solid map-get($colors, 'blue');
    text-align: left;
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
    margin-bottom: 20px;
    align-items: flex-end;

    > span {
      padding-bottom: 0.33em;
    }

    .input {
      margin: 0;
    }

    .rightButtonContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-content: center;
      margin-top: 0;
      margin-bottom: 100;
    }

    .richTitle {
      font-weight: normal;
    }
  }

  .padding {
    padding: 20px;
  }

  .footer {
    height: 74px;
    width: inherit;
    border-radius: 0px 0px 9px 9px;
    display: flex;
    position: relative;
    bottom: 0;
    padding-top: 20px;
  }

  &.wide {
    width: calc(100% - 40px);
    margin: 20px;
  }
  &.summaryCardWide {
    width: 1100px;
  }

  .tabs ~ & {
    margin-top: 0;
  }
}

.noPadding {
  padding: 0px;

  .title {
    width: 100%;
    height: 40px;
    margin: auto;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    font-size: 18px;
    line-height: 21px;
    color: map-get($colors, 'white');
    background: map-get($colors, 'blue');
    border-radius: 5px 5px 0px 0px;
    text-align: left;

    .input {
      margin: 0;
    }
  }

  .header {
    margin: 0px 20px 4px 20px;
    padding: 10px 20px 0px 0px;

    > span {
      padding-bottom: 0.33em;
    }
  }
}

.smallCard {
  width: 920px;
}

.border {
  border: 2px solid map-get($colors, 'grey-stroke');
}
