@import '../../../styles/colors.scss';

$previewHeight: 445px;
$previewWidth: 445px;
$previewOuterPadding: 30px;
$previewHeaderHeight: 28px;
$previewBorderRadius: 10px;
$previewBorderSize: 1px;
$previewInnerPadding: 6px;

.widget {
  display: grid;
  grid-template-columns: 1fr #{$previewWidth + 2 * $previewOuterPadding + 2 * $previewInnerPadding};
}

.selects {
  grid-row: 1;
  grid-column: 1;
}

.preview {
  grid-row: 1;
  grid-column: 2;
  border-radius: $previewBorderRadius;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: start;
  display: block;
  border: #{$previewBorderSize} solid #{map-get($colors, 'grey-hover')};

  .previewHeader {
    background-color: map-get($colors, 'grey-hover');
    color: map-get($colors, 'white');
    height: $previewHeaderHeight;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: $previewBorderRadius - $previewBorderSize;
    border-top-right-radius: $previewBorderRadius - $previewBorderSize;
  }

  .previewBody {
    padding: $previewInnerPadding;
    width: $previewWidth;
    height: $previewHeight;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: $previewBorderRadius - $previewBorderSize;
    border-bottom-left-radius: $previewBorderRadius - $previewBorderSize;

    &.previewPending {
      background-color: map-get($colors, 'grey-bkgd');
    }
  }
}

.container {
  display: flex;
  justify-content: space-around;
  overflow: scroll;
}

.trend {
  margin-top: 20px !important;
}

.filterHeader {
  text-align: left;
}

.noCardMessage {
  justify-content: center;
  align-items: center;

  .noCard,
  .createMessage {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: map-get($colors, 'grey-stroke');
  }

  .createMessage {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
}
