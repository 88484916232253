.reportTask {
  height: 164px;
  width: 180px;
  margin: 20px auto;
  position: relative;
}

.reportTaskCard {
  height: inherit;
  width: inherit;
  margin: inherit;
  position: relative;
}

.reportTask:hover .reportTaskCard {
  background: rgba(0, 0, 0, 0.5);
  filter: blur(2px);
  border-radius: 10px;
}

.reportTaskCard-createdDate {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #5f5f5f;
  padding: 0 7.5px;
}

.reportTaskCard-header {
  width: inherit;
  height: 26px;
  margin: 0;
}

.reportTaskCard-headerText {
  padding-top: 5px;
}

.blueHeading {
  background: #008284;
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.greenHeading {
  background: #2f8738;
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.redHeading {
  background: #c74846;
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.reportTaskCard-content {
  width: inherit;
  height: 126px;
  border: 1px solid #949494;
  border-radius: 0px 0px 10px 10px;
}

.reportTaskCard-contentSummary {
  width: 178px;
  height: 75px;
  background: #f1f2f2;
  border-bottom: 1px solid #949494;
  padding: 5px;
}

.reportTaskCard-reportContainerBlue {
  border: 1px solid #008284;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #008284;
}

.reportTaskCard-reportContainerRed {
  border: 1px solid #c74846;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #c74846;
}

.reportTaskCard-reportContainerGreen {
  border: 1px solid #2f8738;
  border-radius: 18px;
  background: #fff;
  height: 36px;
  color: #2f8738;
}

.reportTaskCard-reportContainerText {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  padding-top: 9px;
}

.reportTaskCard-dueDate {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding-top: 5px;
}

.reportTaskCard-dueDate > span {
  font-weight: bold;
}

.reportTaskCard-assigneeBlue {
  background: #008284;
  border-radius: 10px;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 74px;
  height: 15px;
  margin: auto;
  position: relative;
  top: -7.5px;
}

.reportTaskCard-assigneeRed {
  background: #c74846;
  border-radius: 10px;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 74px;
  height: 15px;
  margin: auto;
  position: relative;
  top: -7.5px;
}

.reportTaskCard-assigneeGreen {
  background: #2f8738;
  border-radius: 10px;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 74px;
  height: 15px;
  margin: auto;
  position: relative;
  top: -7.5px;
}

.reportTaskCard-assignee {
  width: inherit;
  height: 49px;
  display: flex;
  padding: 5px;
  position: relative;
  top: -9.5px;
}

.reportTaskCard-assignee div.name.name span {
  font-style: italic;
  font-size: 14px;
}

.reportTaskCard-buttons {
  display: none;
}

.reportTask:hover .reportTaskCard-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 36px;
  transform: translateY(-325%);
}

.reportTask:hover .reportTaskCard-buttons * {
  min-width: 75px;
  margin: auto;
}

.reportTaskCard-customContainer {
  background: #fff;
  border: 1px solid #949494;
}

.reportTaskCard-customTaskText {
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.reportTaskCard-customTaskTextLabel {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin-left: 10px;
  width: 150px;
  text-overflow: ellipsis;
}
