@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.buttonImage,
.cameraButtonImage {
  width: 24px;
  height: 24px;
  margin: 0px 10px;
}

.cameraButtonImage {
  height: 20.25px;
}

.text,
.blueText,
.greenText,
.redText {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  padding-bottom: 10px;
}

.blueText {
  color: map-get($colors, 'blue');
  font-weight: bold;
}

.greenText {
  color: map-get($colors, 'green');
  font-weight: bold;
}

.redText {
  color: map-get($colors, 'red');
  font-weight: bold;
}

.docList {
  display: flex;
  padding-top: 1em;
  flex-wrap: wrap;
}

.carousel {
  padding: 1em 5px 0;
}

.attachmentText {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
