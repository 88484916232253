.osha301List {
  display: flex;
  flex-flow: row nowrap;
}

.osha301List-caseList {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 344px;
}

.osha301List-caseList--table {
  width: 100%;
  border-collapse: collapse;
}

.osha301List-caseList--table th {
  font-size: 18px;
  padding-right: 15px;
  text-align: left;
}

.osha301List-caseList--table td {
  border-bottom: 1px solid #c4c4c4;
  font-size: 14px;
  padding-right: 15px;
  padding-top: 30px;
}

.paddingBottom {
  padding-bottom: 10px;
}

.osha301List-caseList--caseWidth {
  max-width: 59px;
  height: 32px;
  padding-top: 10px;
}

.osha301List-caseList--workerName {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
  padding-top: 10px;
}

.osha301List-caseList--jobTitle {
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
  padding-top: 10px;
}

.leftAlign {
  text-align: left;
}

.osha301List-caseInformation {
  position: absolute;
  top: 21px;
  right: 20px;
  bottom: 23px;
  left: 379px;
  border: 2px solid #c4c4c4;
  display: flex;
  padding: 40px 20px;
  margin-top: 75px;
}

.osha301List-caseInformation--leftSide {
  border-right: 1px solid #c4c4c4;
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.osha301List-caseInformation--rightSide {
  width: 50%;
  margin-left: 20px;
}

.osha301List-button {
  position: relative;
  top: 15px;
}

.osha301List-button--div {
  position: absolute;
  margin: 0 auto;
  right: 15px;
}

.osha301List-caseInformation--section {
  text-align: left;
}

.osha301List-caseInformation--section h4 {
  text-align: left;
  font-size: 18px;
  font-style: oblique;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.osha301List-caseInformation--sectionField {
  width: 95%;
  padding: 0;
  margin: 17px 0;
  font-size: 18px;
}

.osha301List-caseInformation--sectionFieldNoBorder {
  width: 95%;
  padding: 0;
  margin: 26px 0;
  font-size: 18px;
}

.osha301List-caseInformation--sectionLabel {
  text-align: left;
  font-style: oblique;
  font-weight: lighter;
  margin: 0 5px 0 0;
}

.osha301List-caseInformation--fieldCombo {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.underline {
  border-bottom: 1px solid #c4c4c4;
  padding: 0 5px;
}

.underline-fullWidth {
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 333px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.underline-justUnderFullWidth {
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.underline-cityWidth {
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.underline-allWidth {
  border-bottom: 1px solid #c4c4c4;
  max-width: 387px;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-top: 10px;
  white-space: pre-wrap;
  overflow: hidden;
}

.underline-caseNumberWidth {
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 269px;
}

.rowSelectedComplete {
  border: 2px solid #a6cb8a !important;
}

.rowSelectedIncomplete {
  border: 2px solid #ff0000 !important;
}

.isIncomplete:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

.isComplete:hover {
  background-color: rgba(166, 203, 138, 0.5);
}

.inCompleteIcon {
  position: absolute;
  left: -20px;
  bottom: -3px;
}

.osha301List-incompleteModal {
  font-weight: 600;
  font-size: 18px;
  color: #ff0000;
}

.oshaListErrorTheme {
  color: #fff !important;
  background-color: #804040 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2)) !important;
  width: 300px;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
  z-index: 9999 !important;
}

.tooltip {
  color: #fff !important;
  background-color: #595959 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2)) !important;
  max-width: 300px;
  font-size: 14px !important;
  padding: 5px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: auto;
  white-space: pre-wrap;
}
