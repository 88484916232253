@import '../../styles/colors.scss';

input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: -100;
  opacity: 0;
  width: 155px;
  height: 36px;
  cursor: pointer;
}

.addDocs {
  display: flex;
  align-items: center;
}

.requiredDocs {
  @include color('red', $text: true, $shadow: true, $invert: true);
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-left: 20px;
}

.padding {
  padding-top: 20px;
}
