.dropzone img {
  width: auto;
  height: 50px;
}

.dropzone {
  width: 180px;
  height: 180px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    max-width: 100%;
  }
}
