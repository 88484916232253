@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.App {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  background-color: map-get($colors, 'grey-bkgd');

  > * {
    flex: 1 100%;
  }
}

.mainPage {
  background-color: map-get($colors, 'grey-bkgd');
  height: 100%;
  width: 80vw;
  overflow-y: auto;
  @include media('>tablet') {
    flex: 1 0px;
    order: 2;
  }
}
