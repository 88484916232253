@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.infoCard {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  line-height: 2.25;
  white-space: nowrap;

  .infoLabels {
    font-weight: bold;
    font-size: 18px;
    margin-right: 40px;
  }

  .info {
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.infoAndQrFlexbox {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  height: 615px;

  .equipmentInfo {
    max-width: 640px;
    height: 100%;
    margin: 10px;
  }

  .qrCodeCard {
    max-width: 300px;
    height: 100%;
    margin: 10px;
    height: 415px;

    .qrCode {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
.AssignablesList {
  margin: 30px;
  border-top: 2px solid map-get($colors, 'grey-stroke');

  .equipmentAttachmentLists {
    width: inherit;
    margin: 30px;
  }
}

.pencil {
  cursor: pointer;
}

.typeSelection {
  justify-content: center;
}

.tooltip {
  overflow: auto;
  white-space: pre-wrap;
}

.fieldValidation {
  input {
    background: map-get($colors, 'red-light');
    border-color: map-get($colors, 'red');
  }
}
