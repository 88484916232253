@import '../../styles/globals.scss';

.incidentContainer-mainPage .loading {
  position: relative;
  left: 300px;
  top: -8px;
}

.incidentContainer-headerInfo--incident {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  text-align: left;
  justify-content: center;
  margin-left: 10px;
}

.incidentContainer-mainPage {
  display: flex;
  flex-flow: row wrap;
  padding-top: 20px;
}

.incidentContainer-mainPage--left {
  height: inherit;
  width: 75%;
  margin: 0 20px;
  overflow-y: auto;
}

.incidentContainer-mainPage--categorization {
  display: flex;
  justify-content: space-between;
  height: 55px;
}

.incidentContainer-reportCategories {
  min-width: 185px;
  max-width: fit-content;
  height: 38px;
  background: #ffffff;
  border: 1px solid #008284;
  border-radius: 19px;
  margin: 7.5px 0 0;
  padding: 5px 10px;
  display: flex;
  position: relative;
  left: 40%;
  @include media('<=tablet') {
    left: 0%;
  }
}

.incidentContainer-mainPage--categorization.claims
  > .incidentContainer-reportCategories {
  left: 0%;
}

.incidentContainer-mainPage--categorization.claims .file-claim-button {
  display: block;
}

.incidentContainer-mainPage--categorization .file-claim-button {
  display: none;
}

.incidentContainer-reportCategories--reported {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin: 0 10px 0 0;
  padding: 0;
  text-align: left;
}

.incidentContainer-reportCategories > svg {
  width: 28px;
  height: 28px;
}

.incidentContainer-incidentCard {
  background: #ffffff;
  border: 1px solid #008284;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  min-height: 591px;
}

.incidentContainer-incidentCard--content {
  width: inherit;
  min-height: 527px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 24px;
}

.incidentContainer-cardContainer {
  width: inherit;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 20px 10px 0px;
}

.incidentContainer-mainPage--right {
  height: inherit;
  width: 20%;
  justify-content: flex-end;
  margin-right: 20px;
}

.incidentContainer-editPermissions {
  height: 450px;
}

.incidentContainer-editPermissions--stages {
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.incidentContainer-editPermissions--stages div {
  width: 22%;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.incidentContainer-editPermissions--stages div:first-of-type {
  width: 33%;
}

.incidentContainer-editPermissions--userList {
  list-style: none;
  width: inherit;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 230px;
  overflow-y: auto;
  padding: 0;
}

.incidentContainer-editPermissions--userList > li {
  padding: 5px;
  height: 49px;
  display: flex;
  justify-content: space-between;
}

.incidentContainer-editPermissions--userList li:nth-child(2n) {
  background: #f1f2f2;
}

.incidentContainer-editPermissions--userList li .userTable {
  width: 22%;
  padding-left: 10px;
}

.incidentContainer-editPermissions--userList li .userTable:first-of-type {
  width: 33%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.incidentContainer-editPermissions--user {
  background: #ffffff;
  border-radius: 18px;
  width: 130px;
  align-items: center;
}

.incidentContainer-editPermissions--user div.name.name span {
  font-style: italic;
  font-size: 14px;
}

.incidentContainer-editPermissions--userList
  li:nth-child(2n + 1)
  .incidentContainer-editPermissions--user {
  background: #f1f2f2;
}

.userTable {
  display: flex;
}

.userTable .removePermissions {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.incidentContainer-editPermissions--user .profileBadge {
  height: 100%;
}

.incidentContainer-editPermissions--grow {
  flex-grow: 1;
}

.incidentContainer-rightSide {
  display: none;
}

.blueText {
  color: #008284;
  font-weight: bold;
}

@media all and (max-width: 1024px) {
  .incidentContainer-mainPage--left {
    width: 100%;
  }

  .incidentContainer-header {
    position: sticky;
    z-index: 10;
  }

  .incidentContainer-mainPage--right {
    display: none;
  }

  .incidentContainer-rightSide {
    height: 50px;
    background-color: #008284;
    border-radius: 10px;
    z-index: 1000;
    position: -webkit-sticky;
    position: fixed;
    top: 45%;
    display: block;
    left: 96%;
    transition: all 0.75s ease;
  }

  .incidentContainer-rightSideExpanded {
    height: 50px;
    background-color: #008284;
    border-radius: 10px;
    z-index: 1000;
    position: -webkit-sticky;
    position: fixed;
    top: 45%;
    display: block;
    left: 67%;
    transition: all 0.75s ease;
  }

  .incidentContainer-rightSide--activityLog {
    display: flex;
    justify-content: flex-start;
  }

  .incidentContainer-rightSide--activityLog .activityLogImage {
    height: 37px;
    margin-right: 20px;
    margin-top: 7px;
    top: 45%;
  }

  .incidentContainer-rightSideExpanded
    .incidentContainer-rightSide--activityLog
    .activityLogImage {
    transform: rotate(180deg);
  }

  .incidentContainer-rightSide
    .incidentContainer-rightSide--activityLog
    .reportActionsContainer {
    top: 18%;
    position: fixed;
    left: 100%;
    transition: all 0.75s ease;
    visibility: hidden;
  }

  .incidentContainer-rightSideExpanded
    .incidentContainer-rightSide--activityLog
    .reportActionsContainer {
    top: 18%;
    position: fixed;
    left: 73%;
    transition: all 0.75s ease;
  }
}
