a svg {
  fill: #fff;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}

a:hover .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #3a3b3d;
}
