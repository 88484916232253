@import '../../../styles/colors.scss';

.bar {
  width: 66%;
  border-radius: 14px;
  margin-right: 5px;
  margin-left: 20px;
  padding: 2px;
  display: flex;

  .barPiece {
    height: 15px;
    border-radius: 14px;
    padding: 2px;
    @include colorStyling($background: true);

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.container {
  text-align: left;
  .headerText {
    margin: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }
  .stats {
    min-width: 50%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .chartDivs {
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    .progressBar {
      align-self: center;
      margin-right: 0;
      margin-bottom: 10px;
    }

    .timeSpent {
      white-space: nowrap;
      font-size: 18px;
      line-height: 21px;
      margin-right: 20px;
      margin-left: 5px;
      min-width: 8em;
    }
    .check {
      align-self: center;
      width: 24px;
      height: 25px;
    }
  }
}
