@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

ul.gearMenu {
  $gearMenu: &;
  border-radius: $inputRadius;
  list-style: none;
  padding: 0;
  margin: 0;
  background: map-get($colors, 'white');
  cursor: pointer;
  height: 30px;

  div.right {
    right: 5px;
    display: flex;
    align-items: center;
    > img {
      content: url('../../assets/images/downChevron.png');
      width: 10px;
      height: 5px;
      margin: 0;
      padding: 0;
      padding-right: 2px;
      margin-right: 2px;
      transition: 0.3s;

      @include atContext($gearMenu, '.disabled') {
        visibility: hidden;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: map-get($colors, 'grey-bkgd');
    border: none;
  }

  &.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #{map-get($colors, 'grey-stroke')};
  }

  > li.menu {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: $inputRadius - 1;
    align-items: center;
    @include colorHover('white');

    @include atContext($gearMenu, '.disabled') {
      filter: opacity(50%);
    }

    @include atContext($gearMenu, '.open') {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background: map-get($colors, 'white-hover');
    }

    svg path {
      fill: map-get($colors, 'blue');
    }

    .text {
      color: map-get($colors, 'blue');
      padding: 0 0.5em;
    }
  }

  > li.wrapper {
    position: relative;
    > ul {
      margin: 0;
      padding: 5px;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: visible;
      position: absolute;
      z-index: 500;
      background-color: map-get($colors, 'white');
      border: 1px solid #{map-get($colors, 'grey-stroke')};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      left: -1px;
      min-width: 120px;
      top: 1px;
      margin-top: -1px;
      display: none;
      @include atContext($gearMenu, '.open') {
        display: initial;
      }

      > li {
        max-height: fit-content;
        text-align: left;
        @include colorStyling(
          $text: true,
          $background: true,
          $hover: true,
          $invert: true
        );
        border-radius: 5px;

        > div {
          font-weight: normal;
          font-size: 14px;
          padding: 5px;
        }
      }
    }
  }
}
