@import '../../../styles/colors.scss';

.editor {
  background: map-get($colors, 'white');
  border-radius: 5px;
  border-color: map-get($colors, 'red');
  min-height: 100px;
  font-size: 16px;
  cursor: auto;
}

.everything {
  text-align: left;
  --rmr-color-background: red;
  // text color on button no other things
  // --rmr-color-primary-text: blue;
  // --rmr-color-primary-text: black;
  // // color of button no other things
  // // this is the purple button
  // // --rmr-color-primary: #7963d2;
  // --rmr-color-primary: hsla(0, 0%, 85%, 1);
  // --rmr-color-active-primary-text: blue;
  // --rmr-color-text: teal;
  // --rmr-color-background: purple;
  // // this is the color purple that is defaul
  // --rmr-color-outline: rgba(121, 99, 210, 0.4);

  // not diabled and active things
  // color: var(--rmr-color-active-primary-text);
  --rmr-color-active-primary-text: blue;
  // border-color: var(--rmr-color-active-border);
  --rmr-color-active-border: green;
  --rmr-color-active-primary: teal;
  // background-color: var(--rmr-color-active-primary);

  // hover not diabled
  // color: var(--rmr-color-hover-primary-text);
  --rmr-color-hover-primary-text: red;
  // border-color: var(--rmr-color-hover-border);
  --rmr-color-hover-border: purple;
  // background-color: var(--rmr-color-hover-primary);
  --rmr-color-hover-primary: peru;
}

.remirrorTheme {
  --rmr-color-outline: hsla(0, 0%, 0%, 0.25);
  --rmr-color-background: #ffffff;
  cursor: auto;
}

// this is janky looking but does do the error state stuff
.remirrorThemeError {
  // this makes sure if you click on the textbox during error state the outline stays red instead of the default purple
  --rmr-color-outline: map-get($colors, 'red-light');
  // this makes sure the text box has a red border for error states
  --rmr-color-border: map-get($colors, 'red-light');

  background: map-get($colors, 'red-light');
  box-shadow: hsl(1, 48%, 43%) 0px 0px 0px 0.2em;
}

//these 2 look nicer but you have to click on the textbox for the background to turn red
// keeping this so I can come back to it at a later time
// .remirrorThemeError {
//   // this makes sure if you click on the textbox during error state the outline stays red instead of the default purple
//   --rmr-color-outline: hsl(1, 48%, 43%);
//   // this makes sure the text box has a red border for error states
//   --rmr-color-border: hsl(1, 48%, 43%);
// }
// .editorError {
//   background: map-get($colors, 'red-light') !important;
//   // outline: map-get($colors, 'red-hover');
//   // box-shadow: hsl(1, 48%, 43%) 0px 0px 0px 0.2em;
// }

.remirror-button-active {
  color: var(--rmr-color-primary-text) !important;
  background-color: var(--rmr-color-primary) !important;
}

.remirror-button {
  display: inline-flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375em 0.75em;
  line-height: 1.5;
  border-radius: var(--rmr-radius-border);
  text-decoration: none;
  border: 1px solid var(--rmr-color-border);
  cursor: pointer;
  white-space: nowrap;
  color: var(--rmr-color-text);
  background-color: var(--rmr-color-background);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-size: 100%;
}

.remirror-button[aria-disabled='true'] {
  cursor: auto;
}

.remirror-button:not([aria-disabled='true']):hover {
  color: var(--rmr-color-hover-primary-text);
  border-color: var(--rmr-color-hover-border);
  background-color: var(--rmr-color-hover-primary);
}

.remirror-button:not([aria-disabled='true']):active,
.remirror-button:not([aria-disabled='true'])[data-active],
.remirror-button:not([aria-disabled='true'])[aria-expanded='true'] {
  color: var(--rmr-color-active-primary-text);
  border-color: var(--rmr-color-active-border);
  background-color: var(--rmr-color-active-primary);
}
