@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

$carousel-height: 200px;

.carouselWrapper {
  border-radius: $outlineBorderRadius;
  overflow: hidden;
}

.carousel {
  margin-top: 10px;
  width: 100%;
  height: $carousel-height;
  background: map-get($colors, 'grey-stroke');
  display: flex;
  justify-content: space-between;
  border-radius: $outlineBorderRadius;

  > img {
    height: $carousel-height;
    object-fit: contain;
  }

  .imageContainer {
    height: $carousel-height;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .mainImage {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }

    .actionItems {
      display: none;
    }

    .delete {
      position: absolute;
      top: 10px;
      right: 10px;
      fill: map-get($colors, 'white');

      &:hover {
        fill: map-get($colors, 'white-hover');
      }
    }

    .imageNum {
      @include color('white', $background: true, $text: true);
      position: absolute;
      top: 10px;
      left: 10px;
      margin: 0;
      padding: 5px 8px 3px 8px;
      border-radius: 50px;
      font-weight: bold;
    }

    .attribution {
      @include color('grey-bkgd', $background: true, $text: true);
      position: absolute;
      bottom: 10px;
      margin: auto;
      padding: 5px 10px;
      border-radius: 50px;
      min-width: 260px;
      font-size: 0.75em;

      p {
        margin: 0;
      }

      .alignLeft {
        float: left;
        margin-right: 1em;
      }

      .alignRight {
        float: right;
        margin-left: 1em;
      }
    }

    &:hover {
      cursor: pointer;

      .mainImage {
        background: map-get($colors, 'grey-hover');
        filter: opacity(0.6);
      }

      .actionItem {
        display: block;
      }

      .actionItems {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        height: $carousel-height;
        align-items: center;
      }
    }
  }

  .notesContainer {
    background: map-get($colors, 'white');
    height: 160px;
    width: 90%;
    margin: 10px 0;
    text-align: left;
    padding: 10px;

    > div {
      display: flex;
      flex-flow: row nowrap;

      .createdByInfo {
        margin: 5px 0 10px 10px;
        display: flex;
        flex-flow: column nowrap;
      }

      .profileBadge {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.oneItem {
  justify-content: space-evenly;
}

.statusCarousel {
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}

.carouselHeader {
  text-align: left;
  margin-bottom: 0;
}

.status {
  margin: 0 0 -10px 0;
  text-transform: uppercase;
  padding: 0.375em 0 0.25em;

  &.defective {
    @include color('red', $background: true, $text: true);
  }

  &.correction {
    @include color('green', $background: true, $text: true);
  }
}

.arrows {
  border: 1px solid;
  @include color('white', $background: true, $border: true, $hover: true);
  fill: map-get($colors, 'grey');
  width: 40px;
  height: $carousel-height;
  border-top-left-radius: $outlineBorderRadius;
  border-bottom-left-radius: $outlineBorderRadius;
  padding: 0 4px 0 2px;

  &:last-child {
    transform: scaleX(-1);
  }

  &:hover {
    cursor: pointer;
    fill: map-get($colors, 'grey-hover');
  }

  &.withStatus {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
