.switch {
  display: block !important;
}

.container {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .label {
    margin-top: 10px;
    margin-left: 15px;
    font-weight: normal;
  }
}
