@import '../../styles/colors.scss';

.typeSelection {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;

  > div {
    min-width: 120px;
    border: 1px solid;
    @include color('white', $border: 'grey-stroke', $background: true);
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    padding: 10px;
    text-align: center;
    text-shadow: none;

    &:first-of-type {
      border-radius: 24px 0px 0px 24px;
      padding-left: 12px;
    }

    &:last-of-type {
      border-radius: 0px 24px 24px 0px;
      padding-right: 12px;
    }
  }

  .toggleSelected {
    @include color('blue', $text: true, $background: true, $border: 'blue');
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    text-decoration: underline;
  }
}
