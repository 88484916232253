.sameLine {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .topMargin {
    margin-top: 30px;
  }
}

.button {
  width: 147px;
}
.adminContainer {
  background: #f1f2f2;
  margin: 15px;
  padding: 10px;

  .adminInfo {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    left: 10px;
  }
}

.specialGrid {
  grid-template-columns: 16fr 1fr;
}

.removeImage {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
}
