@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

.tagRow {
  margin-top: 0;
  padding-top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .noBulletList {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    align-items: center;

    .tagInput {
      width: 33%;
    }
  }
  .removeButton {
    margin-left: 5px;
  }
}

.tagList {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .tag {
    justify-content: space-between;
    border-radius: 20px;
    @include color('blue', $background: true, $text: true);
    margin: 10px;
    display: flex;
    padding: 5px;
  }
}
