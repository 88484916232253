@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .rightChevron svg {
    transform: rotate(180deg);
  }

  .rightChevron,
  .leftChevron {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.disabled) {
      cursor: pointer;
    }
    &.disabled svg {
      fill: map-get($colors, 'grey-stroke');
    }
  }

  .pages {
    height: 100%;
    padding: 0 1em;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
  }

  svg {
    fill: map-get($colors, 'blue');
  }
}
