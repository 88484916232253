@import '../../../styles/colors.scss';

.trainingContainer {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;

  .fileUpload {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: -100;
    opacity: 0;
    width: 155px;
    height: 36px;
  }

  .attachmentContainer {
    margin-top: 20px;
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    box-shadow: inset map-get($colors, 'black-shadow');
    height: fit-content;
  }

  .beforeExpires {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .topMargin {
      margin-top: 31px;
    }
  }
}

.adminContainer {
  background: #f1f2f2;
  margin: 15px;
  padding: 10px;

  .adminInfo {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    left: 10px;
  }
}

.specialGrid {
  grid-template-columns: 16fr 1fr;
}

.removeImage {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  margin-top: 25px;
}

.button {
  width: fit-content;
}
