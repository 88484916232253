@import '../../styles/colors.scss';

.notesContainer {
  margin: 10px 0;
  text-align: left;
  padding: 10px;
  background: map-get($colors, 'white');

  div.note {
    display: flex;
    align-items: center;
    margin: 10px 0 0 60px;
    font-weight: 300;

    .textbox {
      width: 70%;
    }
    .edit {
      justify-self: flex-end;
      width: 30px;
      height: 30px;
      margin-left: 10px;
      cursor: pointer;
      > img {
        content: url('../../assets/images/editName.png');
        width: inherit;
        height: inherit;
        &:hover {
          content: url('../../assets/images/editNameHover.png');
        }
      }
    }
  }
}
.sectionCompletedNote {
  background: map-get($colors, 'green-light');
}
