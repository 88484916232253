.oshaInjuredWorker {
  margin: 15px 5px 0;
  padding: 20px;
  background: #eff5f6;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.oshaInjuredWorker .formHeading {
  width: inherit;
  margin-right: 40px;
  border-bottom: 1px solid #c4c4c4;
}

.oshaInjuredWorker-combinationQuestion {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}

.oshaInjuredWorker-combinationQuestion--leftGroup {
  border-right: solid 1px #000;
  padding-right: 40px;
}

.oshaInjuredWorker-csz .city input {
  width: 203px;
}

.oshaInjuredWorker-csz .zip input {
  width: 85px;
}

.oshaInjuredWorker .multiple .date,
.oshaInjuredWorker-result .textbox {
  width: 122px;
}

.oshaInjuredWorker-fillInTheBlanks {
  display: flex;
  flex-flow: row nowrap;
}

.oshaInjuredWorker-fillInTheBlanks span {
  font-style: oblique;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 15px 5px 0px;
}

.oshaInjuredWorker-fillInTheBlanks .textbox {
  margin-right: 0;
  width: 166px !important;
}
