@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.login {
  width: 450px;
  a {
    font-family: Helvetica;
    font-style: italic;
    color: map-get($colors, 'green');
    font-size: 14px;
  }
  label {
    color: map-get($colors, 'green');
  }
  h2 {
    font-size: 24px;
    color: map-get($colors, 'green');
    border-bottom: 2px solid map-get($colors, 'green');
  }
}

.loginAndroidBadge {
  width: 170px;
}

.loginFlexbox {
  height: 100%;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.loginFormVerbage {
  text-align: center;
  padding-top: 0px;
  margin-top: 0px;
}

.loginMessageHeader {
  text-align: center;
  color: map-get($colors, 'green');
}

.loginModalLogo {
  width: 100%;
}

.loginVerbage {
  max-width: 88%;
  width: 480px;
  h2 {
    margin: 20px 0px 20px 0px;
    font-size: 36px;
    line-height: 41px;
    color: map-get($colors, 'green');
  }
  section {
    font-size: 18px;
    display: inline;
    color: map-get($colors, 'green');
  }
  section + section {
    margin-left: 20px;
  }
  button {
    margin-top: 20px;
  }
}

.loginWholePage {
  height: 100%;
  background-color: map-get($colors, 'white');
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modalMobile section {
  text-align: center;
}

.passwordRecovery {
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 24px;
    color: map-get($colors, 'blue');
    text-align: left;
    border-bottom: 2px solid map-get($colors, 'blue');
  }
  p {
    font-size: 14px;
  }
}

.passwordRecoveryWholePage {
  height: 100%;
  background-color: map-get($colors, 'white');
}

.passwordRecoveryBackButton {
  padding: 20px;
  button .greenChevron {
    fill: map-get($colors, 'green');
  }
}
