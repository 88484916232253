.container {
  &.onClick {
    cursor: pointer;
  }
  .hoverOn {
    display: none;
  }
  &:hover {
    .hoverOff {
      display: none;
    }
    .hoverOn {
      display: initial;
    }
  }
}
