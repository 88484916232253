.sdsButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  height: inherit;
}

.sdsButtons-button {
  width: 175px;
  height: 175px;
  margin: 10px 20px;
}

.sdsButtons-button:hover .sdsButtons-button.disabled:hover {
  border: 1px solid #842c00;
  background: hsl(0, 0%, 85%);
  color: white;
}

.sdsButtons-button.activeButton {
  background: #008284;
  color: white;
}
.sdsButtons-button.activeButton:hover,
.sdsButtons-button.activeButton.disabled:hover {
  background: hsl(181, 100%, 18%);
  color: white;
}

.sdsButtons-button.disabledButton {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}

.sdsButtons-button--buttonStuff {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.sdsButtons-button--buttonStuff img {
  width: 100% !important;
}

.sdsButtons-button.disabled,
.sdsButtons-button.activeButton.disabled {
  cursor: not-allowed;
}

.sdsButtons-button.disabled:hover {
  border: 1px solid white;
  background: white;
  color: white;
}
