/* eslint-disable */
// prettier-ignore
$colors: (
  "green":        hsl(126, 48%, 36%),  //#2F8738
  "green-hover":  hsl(126, 48%, 26%),  //#226128
  "green-light":  hsl(126, 27%, 87%),  //#D5E7D7
  "blue":         hsl(181, 100%, 26%), //#008284
  "blue-hover":   hsl(181, 100%, 18%), //#005D5E
  "blue-light":   hsl(180, 34%, 85%),  //#CCE6E6
  "red":          hsl(1, 54%, 53%),    //#C74846
  "red-hover":    hsl(1, 48%, 43%),    //#A13A38
  "red-light":    hsl(0, 54%, 91%),    //#F4DADA
  "yellow":       hsl(48, 100%, 50%),  //#FFCC00
  "yellow-hover": hsl(48, 100%, 35%),  //#B38F00
  "yellow-light": hsl(49, 100%, 93%),  //#FFF8D9
  "grey-stroke":  hsl(0, 0%, 58%),     //#949494
  "grey-bkgd":    hsl(240, 4%, 95%),   //#F1F2F2
  "row-grey":    hsla(240, 2%, 85%, 0.33),   //#F1F2F2
  "black":        hsl(0, 0%, 0%),      //#000000
  "grey":         hsl(0, 0%, 37%),     //#5F5F5F
  "grey-hover":   hsl(0, 0%, 17%),     //#2B2B2B
  "white":        hsl(0, 0%, 100%),    //#FFFFFF
  "white-hover":  hsl(0, 0%, 85%),     //#D9D9D9
  "white-box-shadow": hsl(0, 0%, 90%),     //#E6E6E6
  'nav-grey' : hsla(252, 4%, 14%, 1),
  'nav-grey-hover': hsla(120, 4%, 26%, 1),
  "white-shadow": 0 0 4px rgba(255, 255, 255, 0.5),
  "black-shadow": 0 0 4px rgba(0, 0, 0, 0.25)
);

$textForColor: (
  'blue': 'white',
  'green': 'white',
  'red': 'white',
  'white': 'black',
  'black': 'white',
  'grey': 'white',
  'grey-hover': 'white',
  'redOutline': 'red',
  'greenOutline': 'green',
  'blueOutline': 'blue',
  'greyOutline': 'grey',
  'redError': 'red-hover'
);

$borderForColor: (
  'blue': 'white',
  'green': 'white',
  'red': 'white',
  'white': 'grey-stroke',
  'grey': 'white',
  'redOutline': 'red',
  'greenOutline': 'green',
  'blueOutline': 'blue',
  'greyOutline': 'grey',
  'redError': 'red-hover'
);

$hoverForColor: (
  'blue': 'blue-hover',
  'green': 'green-hover',
  'red': 'red-hover',
  'grey': 'grey-hover',
  'nav-grey': 'nav-grey-hover',
  'white': 'white-hover',
  'redOutline': 'red-light',
  'greenOutline': 'green-light',
  'blueOutline': 'blue-light',
  'greyOutline': 'grey-hover',
  'redError': 'red-light'
);
$shadowForColor: (
  'white': 'black-shadow',
  'black': 'white-shadow',
  'white-shadow': 'white-shadow',
  'black-shadow': 'black-shadow',
  'blue': 'black-shadow',
  'grey': 'white-shadow',
  'grey-stroke': 'white-shadow',
  'red': 'white-shadow',
  'redError': 'red-light'
);
// prettier-ignore
@mixin color($name, $options...) {
  $keys: keywords($options);
  $textColor: if(map-get($keys, 'invert'), $name,  map-get($textForColor, $name));
  $backgroundColor: if(map-get($keys, 'invert'), map-get($textForColor, $name), $name);

  @if map-get($keys, 'invert') {
    @include colorOption($keys, $name, 'text', 'color', $colors);
    @include colorOption($keys, $name, 'background', 'background-color', $textForColor);
  } @else {
    @include colorOption($keys, $name, 'text', 'color', $textForColor);
    @include colorOption($keys, $name, 'background', 'background-color', $colors);
  }

  &:hover { //based on background
    @include colorOption($keys, $backgroundColor, 'hover', 'background-color', $hoverForColor);
  }
  //based on text
  @include colorOption($keys, $textColor, 'shadow', 'text-shadow', $shadowForColor);

  @include colorOption($keys, $name, 'border', 'border-color', $borderForColor);
  svg { //based on text color
    @include colorOption($keys, $textColor, 'svgFillAsText', 'fill', $textForColor);
    @include colorOption( $keys, $textColor, 'svgStrokeAsText', 'stroke', $textForColor);
  }

  
  @if map-get($keys, 'disabled') {
    //maybe change this block
    &:disabled {
      @if (str-index($name, 'Outline')) {
        color: map-get($colors, 'white-hover');
      } @else {
        color: map-get($colors, '#{$name}-light');
        @include colorOption($keys, '#{$name}-light', 'disabled', 'background-color', $colors);
        @include colorOption($keys, $name, 'disabled', 'color', $textForColor);
      }
    }
  }
}

@mixin colorHover($name) {
  @include color($name, $background: true, $hover: true);
}

@mixin colorStyling($keys...) {
  &.green {
    @include color('green', $keys...);
  }
  &.blue {
    @include color('blue', $keys...);
  }
  &.red {
    @include color('red', $keys...);
  }
  &.white {
    @include color('white', $keys...);
  }
  &.grey {
    @include color('grey', $keys...);
  }
  &.redOutline {
    @include color('redOutline', $keys...);
  }
  &.greenOutline {
    @include color('greenOutline', $keys...);
  }
  &.blueOutline {
    @include color('blueOutline', $keys...);
  }
  &.greyOutline {
    @include color('greyOutline', $keys...);
  }
  &.redError {
    @include color('redError', $keys...);
  }
}

@mixin colorOption($keys, $color, $keyName, $propName, $colorMap) {
  @if map-get($keys, $keyName) != false and map-get($keys, $keyName) != null {
    @if $colorMap == $colors {
      $newColor: if(
        map-get($keys, $keyName) == true,
        $color,
        map-get($keys, $keyName)
      );
      #{$propName}: map-get($colors, $newColor);
    } @else {
      $newColor: if(
        map-get($keys, $keyName) == true,
        map-get($colorMap, $color),
        map-get($keys, $keyName)
      );
      #{$propName}: map-get($colors, $newColor);
    }
  }
}

$topNavColor: #2f8738;
