.customDocumentGenerator {
  min-height: 405px;
  max-height: fit-content;
  width: 960px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #008284;
  border-radius: 5px;
}

.customDocumentGenerator-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  border-bottom: 2px solid #008284;
  text-align: left;
  margin: 0;
  padding: 0;
  padding-bottom: 4px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.customDocumentGenerator-header .input {
  margin: 0;
  position: relative;
  top: -10px;
}

.customDocumentGenerator .dateTimePicker {
  margin-bottom: 20px;
}

.customDocumentGenerator-blockText {
  background: #f1f2f2;
  font-size: 18px;
  line-height: 21px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  margin: 20px 0;
  padding: 10px;
  text-align: left;
}
