@import '../../styles/colors.scss';

.report {
  > div {
    border: 1px solid map-get($colors, 'blue');
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      margin-right: 25px;
      cursor: pointer;
      color: map-get($colors, 'red');
      &.email {
        svg {
          margin-right: 13px;
          margin-bottom: 2px;
        }
        fill: map-get($colors, 'blue');
        font-size: 16px;
        font-weight: bold;
        cursor: text;
        margin-right: 0;
        color: map-get($colors, 'black');
      }
    }

    .reportOptions {
      margin-left: auto;
    }
  }
}
