@import '../../styles/colors.scss';

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-variant: small-caps;
}

.noHours {
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
}

.statistic {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  margin-top: 20%;
  margin-bottom: 20%;
  &.preview {
    margin-top: 0%;
  }
}

.standards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 36px;
  margin-top: 0%;

  &.preview {
    margin-top: -35%;
  }

  &.good {
    color: map-get($colors, 'green');
  }

  &.bad {
    color: map-get($colors, 'red');
  }
}
