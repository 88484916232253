@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.badge {
  padding: 0.125em 0.33em;
  border-radius: 10px;
  border: 1px solid;
  font-size: 14px;
  min-height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;

  &.bold {
    font-weight: 700;
  }

  &.square {
    border-radius: $outlineBorderRadius;
  }

  @include colorStyling($background: true, $text: true, $border: true);
}
