@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';
.checkboxInput.checkboxInput {
  margin: 0;
}

.checkboxColumns {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.correct {
  background-color: map-get($colors, 'green-light');
}
.incorrect {
  background-color: map-get($colors, 'red-light');
}
