@import '../../../styles/colors.scss';

.button {
  padding: 10px;
  height: fit-content;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  border: 2px solid;

  &.disabled {
    background: map-get($colors, 'white-hover');
    border: none;
    cursor: not-allowed;
  }

  @include colorStyling(
    $background: true,
    $hover: true,
    $text: true,
    $border: true
  );

  .text {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    @include color('white-shadow', $shadow: true);
    margin-left: 20px;
  }

  .notBold {
    font-weight: normal;
  }
}
