.companyLogo {
  padding: 15px;
}

.companyLogo-noAccess {
  padding: 15px;
  cursor: not-allowed;
}

.companyLogoImage {
  max-width: 550px;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  object-position: left;
  cursor: pointer;
}

.companyLogoImage-noAccess {
  max-width: 550px;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  object-position: left;
  cursor: default;
}

.companyLogo-company {
  display: flex;
  padding-top: 10px;
  position: relative;
}

.companyLogo-companyDiv,
.companyLogo-companyDivHover,
.companyLogo-companyDivWithoutHover {
  font-size: 24px;
  border-radius: 24px 5px 5px 24px;
  max-width: 550px;
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  padding-left: 60px;
  align-items: center;
  color: #fff;
  background-color: #008284;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  white-space: pre;
}

.companyLogo-companyDivWithoutHover {
  cursor: not-allowed;
}

.companyLogo-companyDivHover {
  color: #2ca0a3;
  background-color: #fff;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.companyLogoHover:hover ~ .companyLogo-companyDiv,
.companyLogo-companyDivHover {
  display: none;
}

.companyLogoHover:hover ~ .companyLogo-companyDivHover {
  display: flex;
}

.companyLogoHover {
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: -2px;
  background: #ffffff;
  cursor: pointer;
}

.companyLogoHover-noAccess {
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: -2px;
  background: #ffffff;
  cursor: not-allowed;
}

.toolip {
  max-width: 300px;
  font-size: 28px !important;
  text-align: left;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
  opacity: 1 !important;
  background: hsl(0, 0%, 37%) !important;
  overflow: scroll;
}
