@import '../../../styles/globals.scss';
@import '../../../styles/colors.scss';

.card {
  display: flex;
  justify-content: space-between;

  .created {
    font-size: 18px !important;
  }

  .info,
  .date {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .date {
    justify-content: center;
    text-align: right;
  }

  .topHeader {
    font-size: 18px;
    line-height: 21px;
    @include color('white', $shadow: true);
  }

  .bottomHeader {
    font-size: 18px;
    line-height: 21px;
    @include color('white', $shadow: true);
    font-weight: bold;
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: right;
  padding: 0px 10px;
}

.list {
  list-style-type: none;
  padding: 0;

  li {
    border-bottom: 1px solid #c4c4c4;
    text-align: left;
    padding: 10px;

    &:last-child,
    &:first-child {
      border: none;
    }

    &:first-child {
      background: map-get($colors, 'blue');
      border-radius: 5px 5px 0px 0px;
      color: map-get($colors, 'white');
    }

    > div {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      @include colorStyling($background: true, $text: true, $invert: true);

      > span {
        color: black;
      }
    }
  }
}
