@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

.textbox {
  background: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'grey-stroke');
  box-shadow: inset 0px 0px 3px rgba(191, 191, 191, 1);
  border-radius: 5px;
  min-height: 38px;
  font-size: 16px;
  resize: vertical;
  padding: 7.5px;
  display: block;
  &.invalid {
    background: map-get($colors, 'red-light');
    border: 1px solid map-get($colors, 'red-hover');
  }
}

.textbox:disabled {
  background: map-get($colors, 'grey-bkgd');
  cursor: not-allowed;
  box-shadow: none;
  border: 1px solid map-get($colors, 'grey-bkgd');
}

.textbox::placeholder {
  color: #5f5f5f;
  font-style: oblique;
}

.broadcastMessage {
  font-size: 20px;
  background: map-get($colors, 'white');
  border: none;
  color: map-get($colors, 'black');
}

.fieldValidation::placeholder {
  font-style: oblique;
  color: map-get($colors, 'red-hover');
  font-weight: 500;
}
