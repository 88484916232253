@import '../../styles/colors.scss';
@import '../../styles/globals.scss';

.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  @include color('black', $background: true);
  width: 100%;
  height: fit-content;
  background: #fff;
}

.warningText {
  color: map-get($colors, 'red');
  font-weight: bold;
}

.removeImage {
  align-self: flex-end;
  padding-bottom: 5px;
  max-width: 38px;
}

.grow {
  > * {
    flex-grow: 1;
  }
}
.addButton {
  max-width: 100px;
}
.reassignUser {
  margin-top: 10px;
  display: flex;
  justify-content: center;

  .reassignArrow {
    width: 50px;
    height: 37.18px;
    margin: 20px;
  }
}
.modalButtons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  @include media('<=desktop') {
    grid-template-columns: repeat(2, minmax(20%, 1fr));
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0;
    padding: 0;
  }
}
.button {
  min-width: 30px;
  flex: 1 1 20%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0px !important;
  @media (max-width: 500px) {
    justify-content: center;
  }
}
.modalText {
  font-size: 18px;
}
.buttonText {
  font-size: 18px;
  font-weight: 700;
  margin: 5px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  @media (max-width: 500px) {
    display: none;
  }
}
.imageStyles {
  justify-self: flex-start;
  border-radius: 50px;
  padding: 8px;
  margin: 15px 10px 15px;
}
.selected {
  background-color: map-get($colors, 'white');
  svg {
    fill: map-get($colors, 'blue') !important;
  }
}
.youtubeInput {
  margin-top: 0 !important;
}
.tooltip {
  @media (min-width: 501px) {
    display: none !important;
  }
}
.notSelected {
  background-color: map-get($colors, 'blue');
}

.disabled {
  background-color: map-get($colors, 'grey-stroke');
  svg {
    fill: map-get($colors, 'white') !important;
  }
}

.inputWarning {
  display: flex;
  color: map-get($colors, 'red');
  text-align: left;
  margin-top: 5px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
}

.missingRequiredText {
  color: map-get($colors, 'red');
  font-weight: bold;
  font-size: 20px;
}

.caution {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  margin-left: 5px;
}

.checkboxGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 50px;
}

.checkboxText {
  font-weight: 400;
}

.topMessage {
  font-weight: 400;
  padding: 0;
  max-width: max-content;
  font-size: 20px;
  margin-bottom: 5px;
}

.report {
  > div {
    border: 1px solid map-get($colors, 'blue');
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      margin-right: 25px;
      cursor: pointer;
      color: map-get($colors, 'red');
    }
  }
}

.specialGrid {
  grid-template-columns: 3fr 1fr 1fr;
}

.usersDeactivatedWarning {
  margin: 20px 0 0 5px;
  font-weight: bolder;
  color: map-get($colors, 'red');
}
