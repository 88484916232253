.reportComponentCard {
  width: 150px;
  height: 204px;
}

.reportComponentCard-reportComponent {
  position: relative;
  width: inherit;
  height: 150px;
  border: 2px solid #949494;
  border-radius: 20px;
  padding: 10px 0px;
  cursor: pointer;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.reportComponentCard-reportComponentCompleted {
  border: 2px solid #2f8738;
  position: relative;
  width: inherit;
  height: 150px;
  border-radius: 20px;
  padding: 10px 0px;
  cursor: pointer;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.reportComponentCard-reportComponentPastDue {
  border: 2px solid #c74846;
  position: relative;
  width: inherit;
  height: 150px;
  border-radius: 20px;
  padding: 10px 0px;
  cursor: pointer;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.reportComponentCard-reportComponentStarted {
  border: 2px solid #2ca0a3;
  position: relative;
  width: inherit;
  height: 150px;
  border-radius: 20px;
  padding: 10px 0px;
  cursor: pointer;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.reportComponentCard-reportComponent:hover {
  background: #d9d9d9;
}
.reportComponentCard-reportComponentStarted:hover {
  background: #d9d9d9;
}

.reportComponentCard-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.reportComponentCard-headerCompleted {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #2f8738;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.reportComponentCard-headerPastDue {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #c74846;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.reportComponentCard-marginTop {
  margin-top: 23px;
}

.reportComponentCard-numbersToComplete {
  font-weight: bold;
  font-size: 48px;
  line-height: 41px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.reportComponentCard-reportSectionName {
  margin: auto;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
  max-width: 140px;
}

.reportComponent-assignedTo {
  display: flex;
  width: inherit;
  height: 90px;
}

.reportComponent-assignedTo--background {
  display: flex;
  width: inherit;
  height: 90px;
  background: #cce6e6;
  border: 2px solid #2ca0a3;
  border-radius: 20px;
  position: relative;
  top: -40px;
}

.reportComponent-assignedTo--backgroundCompleted {
  display: flex;
  width: inherit;
  height: 90px;
  background: #d5e7d7;
  border: 2px solid #2f8738;
  border-radius: 20px;
  position: relative;
  top: -40px;
}

.reportComponent-assignedTo--backgroundPastDue {
  display: flex;
  width: inherit;
  height: 90px;
  background: #f4dada;
  border: 2px solid #c74846;
  border-radius: 20px;
  position: relative;
  top: -40px;
}

.reportComponent-assignedTo .button {
  min-width: 73px;
  width: 73px;
  margin-left: calc(45%);
  min-height: 34px;
  height: 34px;
}

.reportComponent-assignedTo--assignee {
  background: #ffffff;
  border-radius: 18px;
  width: 130px;
  height: 34px;
  text-align: left;
  line-height: 16px;
  position: relative;
  bottom: -45px;
  margin: 0 auto;
}

.reportComponent-assignedTo--assignee div.name.name span {
  font-style: italic;
  font-size: 14px;
}

.reportComponentCard-reportLocked {
  position: relative;
  top: -184px;
  left: -20px;
  width: 40px;
  height: 40px;
  z-index: 3;
}

.reportComponentCard-reportLocked img {
  width: 40px;
  height: 40px;
}

.reportComponentCard-lockHover {
  display: none;
}

.assignButton > div {
  line-height: initial;
  white-space: initial;
}
