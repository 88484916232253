.safetyListContainer {
  display: flex;
  flex-direction: column;
}

.createTrainingButton {
  align-self: flex-end;
}

.employeeSnapshot {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
