@import '../../../styles/colors.scss';

.stages {
  width: inherit;
  height: 60px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 10px 0;
  width: 960px;
  align-self: center;
  cursor: pointer;
  .stage:first-child:nth-last-child(1) {
    /* -or- li:only-child { */
    width: 100%;
  }

  /* two items */
  .stage:first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ .stage {
    width: 49%;
  }

  /* three items */
  .stage:first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ .stage {
    width: 32%;
  }

  /* four items */
  .stage:first-child:nth-last-child(4),
  :first-child:nth-last-child(4) ~ .stage {
    width: 21%;
  }
  .stage {
    // width: 30%;
    height: 46px;

    &:first-child > div {
      border-radius: 100px 0 0 100px;
    }
    &:last-child > div {
      border-radius: 0px 100px 100px 0px;
    }

    > div {
      height: 34px;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    > img {
      width: 24px;
      position: relative;
      top: -8px;
    }

    &.stageStarted > div {
      background-color: map-get($colors, 'blue');
    }

    &.stageCompleted > div {
      background-color: map-get($colors, 'green');
    }

    &.stageDisabled > div {
      background-color: map-get($colors, 'grey');
    }

    &.stageStarted:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'blue');
    }

    &.stagePastDue:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'red');
    }

    &.stageCompleted:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'green');
    }

    &.stageDisabled:not(:last-child) > div::after {
      border-left-color: map-get($colors, 'grey');
    }

    &:not(:last-child) > div::after {
      position: relative;
      top: -28px;
      left: 100%;
      display: block;
      border-left: 16px solid map-get($colors, 'grey'); /* width: arrow width */
      border-top: 17px solid transparent; /* width: half height */
      border-bottom: 17px solid transparent; /* width: half height */
      width: 0;
      height: 0;
      content: ' ';
    }

    &:not(:first-child):not(:last-child) > div::after {
      top: -62px;
    }

    &:not(:first-child) > div::before {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      border-left: 16px solid map-get($colors, 'grey-bkgd'); /* width: arrow width */
      border-top: 17px solid transparent; /* width: half height */
      border-bottom: 17px solid transparent; /* width: half height */
      width: 0;
      height: 0;
      content: ' ';
    }

    &.stageSelected {
      padding-bottom: 10px;
      border-bottom: 2px map-get($colors, 'grey') solid;
    }
  }
}
