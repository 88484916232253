@import '../../styles/colors.scss';

.infoBar {
  $lockBannerMargin: 10px;
  $lockBannerHeight: 51px;
  display: flex;
  border-radius: 5px;
  color: white;
  padding: 5px 20px;
  margin: $lockBannerMargin;
  font-size: 16px;
  line-height: 18px;
  height: $lockBannerHeight;
  border: 1px solid;
  box-shadow: 0px 0px 2px 1px map-get($colors, 'white-box-shadow');

  @include colorStyling($background: true, $text: true, $border: true);
}
