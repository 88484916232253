.quizCard {
  text-align: left;

  :first-of-type(div) {
    margin-left: 20px;

    h4 {
      margin-left: 20px;

      ~ div {
        margin-left: 40px;
      }
    }
  }
}

.quizAnswers {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-left: 5px;
}
