@import '../../../styles/colors.scss';
@import '../../../styles/globals.scss';

.container {
  display: flex;
  flex-direction: row;

  .recurring {
    padding: 0;
    > div {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .time {
    padding: 0;
    margin-top: 0;
    input:global(.textbox) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }

  .timeErr {
    padding: 0;
    margin-top: 0;
    input:global(.textbox) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      background: map-get($colors, 'red-light');
      border-color: map-get($colors, 'red-hover');
    }
    ::placeholder {
      color: map-get($colors, 'red-hover') !important;
    }
  }
}
