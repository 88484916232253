@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

@keyframes donut-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

.donut {
  $donut: &;
  width: 100%;
  height: 100%;
  position: relative;

  .frame,
  .circle {
    stroke-width: 0.125rem;
    fill: none;
  }

  .frame {
    stroke: #ccc;
    @include atContext($donut, '.blue') {
      stroke: map-get($colors, 'blue-light');
    }
    @include atContext($donut, '.green') {
      stroke: map-get($colors, 'green-light');
    }
    @include atContext($donut, '.red') {
      stroke: map-get($colors, 'red-light');
    }
  }

  .circle {
    stroke: #000;
    @include atContext($donut, '.blue') {
      stroke: map-get($colors, 'blue');
    }
    @include atContext($donut, '.green') {
      stroke: map-get($colors, 'green');
    }
    @include atContext($donut, '.red') {
      stroke: map-get($colors, 'red');
    }
    stroke-linecap: round;
  }

  .canvas {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: 2;
  }

  .circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: donut-fill 2s reverse;
  }

  .circle[stroke-dasharray='0 100'] {
    stroke: none;
  }

  .text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
