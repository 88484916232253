@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.notebox {
  border-radius: $outlineBorderRadius;
  margin: 20px;
  padding: 10px;
  font-size: 1.125em;
  text-align: left;

  @include color($name: 'grey-bkgd', $text: true, $background: true, $shadow: true);
}
