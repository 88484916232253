@import '../LeftNav/leftNav.module.scss';

$closeButtonWidth: 35px;
$closeButtonMargin: 10px;

.section {
  cursor: pointer;
}

.label {
  font-weight: bold;
  font-size: 14px;
  margin: 3px;
  line-height: 16px;
  color: map-get($colors, 'white');
  padding: 8px 0px 6px 15px;
  border-radius: 5px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    background-color: map-get($colors, 'nav-grey-hover');
  }

  &:before {
    content: url('../../assets/images/whiteChevron.png');
    float: left;

    margin-right: 8px;
    margin-left: 5px;
  }

  &.activeSection:before {
    content: url('../../assets/images/whiteChevron.png');
    rotate: 90deg;
    float: left;
    margin-right: 10px;
    margin-left: 3px;
  }
}

.menu {
  list-style-type: none;
  list-style: none;
  padding: 0;
  height: 0;
  display: none;

  &.activeSection {
    margin: 2px;
    height: fit-content;
    display: block;
  }

  a {
    @include color('black', $text: true, $shadow: true);
    padding: 5px 5px 5px 24px;
    display: flex;
    flex-flow: row nowrap;
    text-decoration: none;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: map-get($colors, 'nav-grey-hover');
      text-align: left;
      border-radius: 5px;
    }

    span {
      margin-top: 3px;
      margin-left: 10px;
      text-align: left;
    }
  }
}

.mobileHeader {
  height: 50px;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: map-get($colors, 'white');
  width: 50%;

  @include media('<=tablet') {
    display: flex;
    justify-content: flex-end;
  }

  @include media('>tablet') {
    display: none;
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.container {
  cursor: pointer;
  max-width: $closeButtonWidth;
  z-index: 3;
  margin: $closeButtonMargin;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: map-get($colors, 'grey-hover');
  margin: 6px 0;
  transition: 0.4s;
}

.change {
  &.bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
    background-color: map-get($colors, 'white');
  }

  &.bar2 {
    opacity: 0;
  }

  &.bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
    background-color: map-get($colors, 'white');
  }
}

.sideMenu {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  width: 100%;
  background-color: map-get($colors, 'grey-hover');

  &.hidden {
    width: 0px;
  }
}

.sections {
  width: calc(100% - (#{$closeButtonWidth} + #{$closeButtonMargin} * 2));
}
