.dropzone-box {
  height: 50px;
}

.dropzone img {
  width: 50px;
  height: 50px;
}
.space {
  margin: 5px;
}

.dropzone {
  margin: 0 5px 0;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  padding: 5px;
}
