@import '../../../styles/colors.scss';
.instructions {
  text-align: left;
}
.button {
  width: fit-content;
}

.image {
  width: 80px;
  height: 80px;
  cursor: pointer;
  object-fit: contain;
  padding: 12px;
}
