@import '../../styles/colors.scss';

.container {
  display: grid;
  grid-template-columns: 200px 500px 200px;
  justify-content: center;
  padding-top: 2rem;
  gap: 1rem;
}

.tools {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  img {
    width: 60px;
  }
}

.canvas {
  cursor: crosshair;
  &.dragAndDrop {
    cursor: initial;
  }
}

.brushes {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: 100%;
  width: max-content;

  .title {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #808080;
    margin: 1px 5px;
    border-bottom: 1px solid #c4c4c4;
  }

  > div:not(.title) {
    background: #ffffff;
    margin: 0 1px;
    width: 76px;
    height: 76px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &:last-child {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    &.selected {
      background-color: #deecde;
    }

    > div {
      font-size: 12px;
      text-align: center;
      @include color('white-shadow', $shadow: true);
    }
  }
}

.dragBin {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  width: 120px;

  .title {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #808080;
    border-bottom: 1px solid #c4c4c4;
  }
}

.section {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  max-width: inherit;
  padding: 10px 0;
  img {
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
  }

  .desc {
    font-size: 12px;
    text-align: center;
  }

  & ~ & {
    border-top: 1px solid #c4c4c4;
  }
}

.pagination {
  position: absolute;
  top: 25px;
  right: 20px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

[draggable='true'] {
  cursor: grab;
}

[draggable='true']:active {
  cursor: grabbing;
}

.spot {
  width: 40px;
  height: 40px;
}
