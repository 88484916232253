@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.container {
  > header {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }
  display: grid;
  grid-template-rows: $headerHeight 1fr $footerHeight;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  > div {
    overflow-y: auto;

    &.left {
      /* Once we move to container margins, this can be for all > div. */
      margin: 1.5rem 1.25rem;
    }
  }

  .children {
    /* Once we move to container margins, this can go bye-bye. */
    padding-bottom: 1.5rem;
  }

  &.locked {
    grid-template-rows: $headerHeight $lockHeaderHeight 1fr $footerHeight;
  }

  &.withLeft {
    @include media('>tablet') {
      grid-template-columns: minmax(240px, 20vw) 1fr;
    }
    @include media('<=tablet') {
      grid-template-columns: 0 1fr;
    }

    > header,
    > footer {
      grid-column: 1/3;
    }
  }

  &.noFooter {
    grid-template-rows: $headerHeight 1fr;
    &.locked {
      grid-template-rows: $headerHeight $lockHeaderHeight 1fr;
    }
  }

  &.bigHeader {
    grid-template-rows: $bigHeaderHeight 1fr $footerHeight;
  }
}

.lock {
  $lockBannerMargin: 10px;
  $lockBannerHeight: 51px;
  background-color: #008284;
  display: flex;
  border-radius: 5px;
  color: white;
  padding: 5px 20px;
  margin: $lockBannerMargin;
  font-size: 16px;
  line-height: 18px;
  height: $lockBannerHeight;

  .lockUser img {
    background-color: white;
  }

  .lockMessage {
    padding-left: 4px;
    display: flex;
    align-items: center;
  }

  + .container {
    height: calc(
      100% - #{$footerHeight + $headerHeight + $lockBannerHeight + 2 *
        $lockBannerMargin}
    );
    :has(.bigHeader) {
      height: calc(
        100% - #{$footerHeight + $bigHeaderHeight + $lockBannerHeight + 2 *
          $lockBannerMargin}
      );
    }
  }

  + .containerNoFooter {
    height: calc(
      100% - #{$headerHeight + $lockBannerHeight + 2 * $lockBannerMargin}
    );
  }
}
