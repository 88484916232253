.osha300aForm {
  display: flex;
  padding: 10px;
}

.osha300aForm-leftSide {
  width: 593px;
  display: flex;
  flex-flow: column nowrap;
  margin-right: 30px;
  justify-content: left;
}
.osha300aForm-leftSide--establishmentInformation {
  height: 349px;
  border: 2px solid #c4c4c4;
  margin-bottom: 20px;
  padding-left: 10px;
}

.osha300aForm-leftSide--employmentInformation {
  border: 2px solid #c4c4c4;
  height: 277px;
}

.osha300aForm-leftSide--employmentInformation.hasInfo:hover,
.osha300aForm-leftSide--establishmentInformation.hasInfo:hover {
  background-color: rgba(166, 203, 138, 0.5);
}

.osha300aForm-leftSide--employmentInformation.noInfo:hover,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

.osha300aForm-leftSide--header {
  font-style: oblique;
  font-size: 18px;
  text-align: left;
  margin-left: 10px;
  margin-top: 15px;
}

.osha300aForm-rightSide {
  width: 50%;
}

.osha300aForm-leftSide--sectionField {
  width: 95%;
  padding: 0;
  margin: 17px 0 17px 15px;
  font-size: 18px;
  text-align: left;
}

.osha300aForm-leftSide--sectionLabel {
  text-align: left;
  font-style: oblique;
  font-weight: lighter;
  margin: 0 5px 0 0;
}

.osha300aForm-leftSide--sectionFieldNoBorder {
  width: 95%;
  padding: 0;
  margin: 26px 0 26px 15px;
  font-size: 18px;
  text-align: left;
}

.osha300aForm-leftSide--fieldCombo {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 539px;
}

.osha300aForm-leftSide .underline-fullWidth {
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 475px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.osha300aForm-leftSide .underline {
  border-bottom: 1px solid #c4c4c4;
  padding: 0 5px;
}

.osha300aForm-leftSide .underline-allWidth {
  border-bottom: 1px solid #c4c4c4;
  width: 538px;
  margin-left: 10px;
  margin-top: 10px;
}

.osha300aForm-leftSide .underline-cityWidth {
  border-bottom: 1px solid #c4c4c4;
  width: 230px;
  margin-left: 10px;
}

.osha300aForm-leftSide--employmentInformation.noInfo:hover .underline-allWidth,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover
  .underline-allWidth,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover
  .underline-cityWidth,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover .underline,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover
  .underline-fullWidth {
  border-bottom: 1px solid #c49d9d;
}

.osha300aForm-leftSide--employmentInformation.hasInfo:hover .underline-allWidth,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover
  .underline-allWidth,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover
  .underline-cityWidth,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover .underline,
.osha300aForm-leftSide--establishmentInformation.noInfo:hover
  .underline-fullWidth {
  border-bottom: 1px solid #a2b097;
}

.osha300aForm-leftSide--orField {
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  margin-left: 15px;
}

.orField {
  margin: 10px 0;
}

.osha300aForm-leftSide--displayAllInline {
  display: inline-block;
}

.osha300aForm-leftSide--displayAllInline .underline {
  margin: 0 5px;
}

.osha300aForm-rightSide--sections {
  margin-bottom: 30px;
}

.osha300aForm-rightSide--header {
  background: #000;
  color: #fff;
  font-style: oblique;
  width: 605px;
  padding: 0;
  margin: 0 0 10px 0;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.osha300aForm-rightSide--rows {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.osha300aForm-rightSide--fieldLabel {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  width: 148px;
  margin-bottom: 20px;
  height: 36px;
}

.osha300aForm-rightSide--bigLabel {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  width: 226px;
  margin-bottom: 20px;
  height: 36px;
}

.osha300aForm-rightSide--fieldLabelAlignBottom {
  margin-top: 22px;
}

.osha300aForm-rightSide--underline {
  width: 141px;
  border-bottom: 1px solid #c4c4c4;
}

.osha300aForm-rightSide--underlineMargin {
  width: 141px;
  border-bottom: 1px solid #c4c4c4;
  margin-left: 45px;
}

.osha300aForm-rightSide--illnessRows {
  margin-top: 30px;
}

.employmentIcon {
  position: absolute;
  display: block;
  left: 0px;
  bottom: 150px;
}

.establishmentIcon {
  position: absolute;
  display: block;
  left: 0px;
  top: 150px;
}
