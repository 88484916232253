.incidentInfo {
  flex-direction: row;
  display: flex;
  padding: 7.5px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.info {
  font-size: 18px;
}
