.folder {
  text-align: left;
  margin-top: 20px;
  margin-left: 25px;
  margin-bottom: 10px;

  .folderHeader {
    margin: 0;
    padding: 0;
    height: fit-content;

    > span {
      font-size: 24px;
      font-style: italic;
    }

    .editIcon {
      top: 8px;
      position: relative;
      width: 30px;
    }

    .deleteIcon {
      width: 22px;
      top: 4px;
      position: relative;
    }
  }

  .editFolderWrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;

    .editFolder {
      display: flex;
      flex-direction: row;
    }

    .editFolderButton {
      position: relative;
      top: 26px;
    }
  }
}
