.osha300List-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 10px;
}

.osha300List-table th {
  font-size: 18px;
}

.paddingBottom {
  padding: 5px 0 10px 0;
}

.osha300List-table .leftAlign {
  text-align: left;
}

.osha300List-table td {
  border-bottom: 1px solid #c4c4c4;
  font-size: 14px;
}

.osha300List-table--topHeader {
  background: #000;
  color: #fff;
  font-style: oblique;
}

.osha300List-table--firstTopHeader {
  max-width: 344px;
  margin-right: 100px;
}

.osha300List-table--secondTopHeader {
  max-width: 319px;
}

.osha300List-table--thirdTopHeader {
  max-width: 90px;
}

.osha300List-table--fourthTopHeader {
  max-width: 164px;
}

.osha300List-table--fifthTopHeader {
  max-width: 101px;
}

.osha300List-secondWidth {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-top: 10px;
  height: 32px;
}

.osha300List-thirdWidth {
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-top: 10px;
  height: 32px;
}

.osha300List-sixthWidth {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-top: 10px;
  height: 32px;
}

.tooltip {
  color: #fff !important;
  background-color: #595959 !important;
  opacity: 1 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2)) !important;
  max-width: 300px;
  font-size: 14px !important;
  padding: 5px !important;
  text-align: left;
  pointer-events: auto !important;
  overflow: auto;
  white-space: pre-wrap;
}

.osha300List-seventhWidth {
  max-width: 90px;
  padding-top: 10px;
  height: 32px;
}

.osha300List-eighthWidth {
  max-width: 75px;
  padding-top: 10px;
}

.osha300List-tenthWidth {
  max-width: 101px;
  padding-top: 10px;
  height: 32px;
}

.paddingTop {
  padding-top: 10px;
  height: 32px;
}

.isIncomplete:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

.isComplete:hover {
  background-color: rgba(166, 203, 138, 0.5);
}

td:first-child {
  position: relative;
}

.editicons {
  position: absolute;
  left: -30px;
  bottom: -3px;
}

.redLined {
  font-size: 4em;
  line-height: 1em;
  position: relative;
}
.redLined::after {
  border-bottom: 0.125em solid red;
  content: '';
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 60%;
}
